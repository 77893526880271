.order-item {
    border: 1px solid #ccc;
    border-radius: 8px;
    padding: 16px;
    margin-bottom: 16px;
}

.order-header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 8px;
}

.order-body {
    padding-left: 16px;
}

.order-projects {
    margin-top: 8px;
}

.order-project {
    display: flex;
    justify-content: space-between;
}
