blockquote,body,button,code,dd,div,dl,dt,em,fieldset,form,h1,h2,h3,h4,h5,h6,html,input,legend,li,ol,p,pre,td,textarea,th,ul {
    margin: 0;
    padding: 0
}

article,aside,details,figcaption,figure,footer,header,hgroup,menu,nav,section,summary {
    display: block
}

img {
    border: 0;
    margin: 0;
    padding: 0;
    vertical-align: top
}

input,select,textarea {
    border: none;
    font-family: inherit;
    font-size: inherit;
    font-weight: inherit;
    resize: none;
    outline: 0;
    box-shadow: none;
    -webkit-appearance: none
}

table {
    border-collapse: collapse
}

a {
    color: #111;
    text-decoration: none
}

h1,h2,h3,h4,h5,h6 {
    font-weight: 400
}

li,ul {
    list-style: none
}

dfn,em,i {
    font-style: normal
}

body {
    background-color: #ebebeb;
    line-height: 1;
    font-family: PingFangSC-Regular,Helvetica,Microsoft Yahei,tahoma,sans-serif;
    -webkit-user-select: none;
    -webkit-touch-callout: none;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    -webkit-text-size-adjust: none;
    color: #111
}

body,html {
    height: 100%
}

html {
    font-size: 85.3333px
}

body {
    font-size: .14rem
}

@media (min-device-width: 375px) and (max-device-width:667px) and (-webkit-min-device-pixel-ratio:2) {
    html {
        font-size:100px
    }
}

@media (min-device-width: 414px) and (max-device-width:736px) and (-webkit-min-device-pixel-ratio:3) {
    html {
        font-size:110.4px
    }
}

.g-body {
    height: 100%;
    position: relative
}

.top-fix {
    top: 0
}

.bottom-fix,.top-fix {
    position: fixed;
    left: 0;
    right: 0
}

.bottom-fix {
    bottom: 0
}

.clearfix:after,.clearfix:before {
    content: " ";
    display: table
}

.clearfix:after {
    clear: both
}

.flex-wrap {
    display: -ms-flexbox;
    display: flex;
    display: -webkit-box
}

.flex-wrap .flex-item {
    overflow: hidden
}

.flex-item,.flex-wrap .flex-item {
    -ms-flex: 1;
    flex: 1;
    -webkit-box-flex: 1
}

.border-list .border-list-item .icon-chevron-right,.g-pop,.g-pop .g-pop-body .pop-input-item .pic-code-box,.g-pop .g-pop-body .pop-input-item [class*=btn-],.vertical-center {
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%)
}

.g-loading-toast,.horizontal-center {
    position: absolute;
    left: 50%;
    -webkit-transform: translate(-50%);
    transform: translate(-50%)
}

.center {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%,-50%);
    transform: translate(-50%,-50%)
}

.scroll-controller {
    position: fixed;
    overflow: hidden!important;
    width: 100%;
    height: 100%
}

.ellipsis {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap
}

.border-list {
    position: relative
}

.border-list:before {
    top: 0;
    border-top: .01rem solid #e4e4e4;
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    -webkit-transform: scaleY(.5);
    transform: scaleY(.5)
}

.border-list:after,.border-list:before {
    content: " ";
    position: absolute;
    left: 0;
    width: 100%;
    height: .01rem
}

.border-list:after {
    bottom: 0;
    border-bottom: .01rem solid #e4e4e4;
    border-radius: .04rem;
    -webkit-transform-origin: 0 100%;
    transform-origin: 0 100%;
    -webkit-transform: scaleY(.5);
    transform: scaleY(.5)
}

.border-list .border-list-item {
    box-sizing: border-box;
    position: relative;
    background-color: #fff
}

.border-list .border-list-item:after {
    content: " ";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: .01rem;
    border-bottom: .01rem solid #e4e4e4;
    border-radius: .04rem;
    -webkit-transform-origin: 0 100%;
    transform-origin: 0 100%;
    -webkit-transform: scaleY(.5);
    transform: scaleY(.5)
}

.border-list .border-list-item:last-child {
    border-bottom-width: 0
}

.border-list .border-list-item .icon-chevron-right {
    right: .15rem
}

.btn-green {
    background-color: #0c0
}

.btn-green,.btn-red {
    display: inline-block;
    text-align: center;
    color: #fff
}

.btn-red {
    background-color: #f23030
}

.btn-blue {
    background-color: #1fbccf;
    color: #fff
}

.btn-blue,.btn-default {
    display: inline-block;
    text-align: center
}

.btn-default {
    background-color: #fff;
    color: #505050;
    position: relative;
    border-radius: .03rem
}

.btn-default:after {
    content: "";
    width: 200%;
    height: 200%;
    position: absolute;
    left: 0;
    top: 0;
    border: .01rem solid #dcdcdc;
    transform: scale(.5);
    -webkit-transform: scale(.5);
    transform-origin: 0 0;
    -webkit-transform-origin: 0 0;
    box-sizing: border-box;
    border-radius: .06rem
}

.btn-disable {
    background-color: #ccc
}

.g-loading {
    position: relative;
    width: 0;
    height: 33px;
    z-index: 2000000000;
    left: 50%;
    top: 50%
}

.g-loading-leaf {
    position: absolute;
    top: 15px;
    opacity: .25
}

.g-loading-leaf:before {
    content: " ";
    position: absolute;
    width: 8.14px;
    height: 3.08px;
    background: #d1d1d5;
    box-shadow: 0 0 1px rgba(0,0,0,.0980392);
    border-radius: 1px;
    transform-origin: left 50% 0;
    -webkit-transform-origin: left 50% 0
}

.g-loading-leaf-0 {
    animation: opacity-60-25-0-12 1.25s linear infinite;
    -webkit-animation: opacity-60-25-0-12 1.25s linear infinite
}

.g-loading-leaf-0:before {
    transform: rotate(0deg) translate(7.92px);
    -webkit-transform: rotate(0deg) translate(7.92px)
}

.g-loading-leaf-1 {
    animation: opacity-60-25-1-12 1.25s linear infinite;
    -webkit-animation: opacity-60-25-1-12 1.25s linear infinite
}

.g-loading-leaf-1:before {
    transform: rotate(30deg) translate(7.92px);
    -webkit-transform: rotate(30deg) translate(7.92px)
}

.g-loading-leaf-2 {
    animation: opacity-60-25-2-12 1.25s linear infinite;
    -webkit-animation: opacity-60-25-2-12 1.25s linear infinite
}

.g-loading-leaf-2:before {
    transform: rotate(60deg) translate(7.92px);
    -webkit-transform: rotate(60deg) translate(7.92px)
}

.g-loading-leaf-3 {
    animation: opacity-60-25-3-12 1.25s linear infinite;
    -webkit-animation: opacity-60-25-3-12 1.25s linear infinite
}

.g-loading-leaf-3:before {
    transform: rotate(90deg) translate(7.92px);
    -webkit-transform: rotate(90deg) translate(7.92px)
}

.g-loading-leaf-4 {
    animation: opacity-60-25-4-12 1.25s linear infinite;
    -webkit-animation: opacity-60-25-4-12 1.25s linear infinite
}

.g-loading-leaf-4:before {
    transform: rotate(120deg) translate(7.92px);
    -webkit-transform: rotate(120deg) translate(7.92px)
}

.g-loading-leaf-5 {
    animation: opacity-60-25-5-12 1.25s linear infinite;
    -webkit-animation: opacity-60-25-5-12 1.25s linear infinite
}

.g-loading-leaf-5:before {
    transform: rotate(150deg) translate(7.92px);
    -webkit-transform: rotate(150deg) translate(7.92px)
}

.g-loading-leaf-6 {
    animation: opacity-60-25-6-12 1.25s linear infinite;
    -webkit-animation: opacity-60-25-6-12 1.25s linear infinite
}

.g-loading-leaf-6:before {
    transform: rotate(180deg) translate(7.92px);
    -webkit-transform: rotate(180deg) translate(7.92px)
}

.g-loading-leaf-7 {
    animation: opacity-60-25-7-12 1.25s linear infinite;
    -webkit-animation: opacity-60-25-7-12 1.25s linear infinite
}

.g-loading-leaf-7:before {
    transform: rotate(210deg) translate(7.92px);
    -webkit-transform: rotate(210deg) translate(7.92px)
}

.g-loading-leaf-8 {
    animation: opacity-60-25-8-12 1.25s linear infinite;
    -webkit-animation: opacity-60-25-8-12 1.25s linear infinite
}

.g-loading-leaf-8:before {
    transform: rotate(240deg) translate(7.92px);
    -webkit-transform: rotate(240deg) translate(7.92px)
}

.g-loading-leaf-9 {
    animation: opacity-60-25-9-12 1.25s linear infinite;
    -webkit-animation: opacity-60-25-9-12 1.25s linear infinite
}

.g-loading-leaf-9:before {
    transform: rotate(270deg) translate(7.92px);
    -webkit-transform: rotate(270deg) translate(7.92px)
}

.g-loading-leaf-10 {
    animation: opacity-60-25-10-12 1.25s linear infinite;
    -webkit-animation: opacity-60-25-10-12 1.25s linear infinite
}

.g-loading-leaf-10:before {
    transform: rotate(300deg) translate(7.92px);
    -webkit-transform: rotate(300deg) translate(7.92px)
}

.g-loading-leaf-11 {
    animation: opacity-60-25-11-12 1.25s linear infinite;
    -webkit-animation: opacity-60-25-11-12 1.25s linear infinite
}

.g-loading-leaf-11:before {
    transform: rotate(330deg) translate(7.92px);
    -webkit-transform: rotate(330deg) translate(7.92px)
}

@-webkit-keyframes opacity-60-25-0-12 {
    0% {
        opacity: .25
    }

    0.01% {
        opacity: .25
    }

    0.02% {
        opacity: 1
    }

    60.01% {
        opacity: .25
    }

    to {
        opacity: .25
    }
}

@-webkit-keyframes opacity-60-25-1-12 {
    0% {
        opacity: .25
    }

    8.34333% {
        opacity: .25
    }

    8.35333% {
        opacity: 1
    }

    68.3433% {
        opacity: .25
    }

    to {
        opacity: .25
    }
}

@-webkit-keyframes opacity-60-25-2-12 {
    0% {
        opacity: .25
    }

    16.6767% {
        opacity: .25
    }

    16.6867% {
        opacity: 1
    }

    76.6767% {
        opacity: .25
    }

    to {
        opacity: .25
    }
}

@-webkit-keyframes opacity-60-25-3-12 {
    0% {
        opacity: .25
    }

    25.01% {
        opacity: .25
    }

    25.02% {
        opacity: 1
    }

    85.01% {
        opacity: .25
    }

    to {
        opacity: .25
    }
}

@-webkit-keyframes opacity-60-25-4-12 {
    0% {
        opacity: .25
    }

    33.3433% {
        opacity: .25
    }

    33.3533% {
        opacity: 1
    }

    93.3433% {
        opacity: .25
    }

    to {
        opacity: .25
    }
}

@-webkit-keyframes opacity-60-25-5-12 {
    0% {
        opacity: .27095833
    }

    41.6767% {
        opacity: .25
    }

    41.6867% {
        opacity: 1
    }

    1.67667% {
        opacity: .25
    }

    to {
        opacity: .27095833
    }
}

@-webkit-keyframes opacity-60-25-6-12 {
    0% {
        opacity: .375125
    }

    50.01% {
        opacity: .25
    }

    50.02% {
        opacity: 1
    }

    10.01% {
        opacity: .25
    }

    to {
        opacity: .375125
    }
}

@-webkit-keyframes opacity-60-25-7-12 {
    0% {
        opacity: .47929167
    }

    58.3433% {
        opacity: .25
    }

    58.3533% {
        opacity: 1
    }

    18.3433% {
        opacity: .25
    }

    to {
        opacity: .47929167
    }
}

@-webkit-keyframes opacity-60-25-8-12 {
    0% {
        opacity: .58345833
    }

    66.6767% {
        opacity: .25
    }

    66.6867% {
        opacity: 1
    }

    26.6767% {
        opacity: .25
    }

    to {
        opacity: .58345833
    }
}

@-webkit-keyframes opacity-60-25-9-12 {
    0% {
        opacity: .687625
    }

    75.01% {
        opacity: .25
    }

    75.02% {
        opacity: 1
    }

    35.01% {
        opacity: .25
    }

    to {
        opacity: .687625
    }
}

@-webkit-keyframes opacity-60-25-10-12 {
    0% {
        opacity: .79179167
    }

    83.3433% {
        opacity: .25
    }

    83.3533% {
        opacity: 1
    }

    43.3433% {
        opacity: .25
    }

    to {
        opacity: .79179167
    }
}

@-webkit-keyframes opacity-60-25-11-12 {
    0% {
        opacity: .89595833
    }

    91.6767% {
        opacity: .25
    }

    91.6867% {
        opacity: 1
    }

    51.6767% {
        opacity: .25
    }

    to {
        opacity: .89595833
    }
}

.g-loading-toast {
    position: fixed;
    top: 180px;
    z-index: 1200;
    width: 1.44rem;
    padding: .28rem .1rem;
    background: rgba(40,40,40,.75);
    border-radius: .05rem;
    overflow: hidden;
    line-height: .2rem;
    color: #fff;
    font-size: .16rem;
    text-align: center
}

.g-loading-toast .toast-text {
    margin-top: .1rem
}

.g-mask {
    z-index: 1100;
    background: rgba(0,0,0,.6)
}

.g-mask,.g-mask-transparent {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0
}

.g-mask-transparent {
    z-index: 1000
}

.g-toast {
    top: 50%;
    left: .75rem;
    right: .75rem;
    z-index: 1200;
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    padding: .28rem .1rem;
    background: rgba(40,40,40,.75);
    line-height: .2rem;
    color: #fff;
    font-size: .16rem;
    text-align: center
}

.g-pop,.g-toast {
    position: fixed;
    border-radius: .05rem;
    overflow: hidden
}

.g-pop {
    z-index: 1100;
    left: .4rem;
    right: .4rem;
    background: #fff
}

.g-pop .g-pop-head {
    height: .4rem;
    background: #1fbccf;
    position: relative
}

.g-pop .g-pop-head .center {
    line-height: .4rem;
    text-align: center;
    font-size: .14rem;
    color: #fff
}

.g-pop .g-pop-body {
    box-sizing: border-box;
    max-height: 3rem;
    padding: .22rem .2rem;
    overflow-y: scroll;
    font-size: .15rem
}

.g-pop .g-pop-body .pop-input-item {
    margin-bottom: .1rem;
    background: #ebebeb;
    position: relative
}

.g-pop .g-pop-body .pop-input-item:last-child {
    margin: 0
}

.g-pop .g-pop-body .pop-input-item.is-btn {
    padding-right: 1rem
}

.g-pop .g-pop-body .pop-input-item.is-btn input {
    padding-right: 0
}

.g-pop .g-pop-body .pop-input-item input::-webkit-inner-spin-button,.g-pop .g-pop-body .pop-input-item input::-webkit-outer-spin-button {
    -webkit-appearance: none!important
}

.g-pop .g-pop-body .pop-input-item input {
    -webkit-appearance: none;
    width: 100%;
    height: .5rem;
    line-height: .2rem;
    padding: .15rem 0 .15rem .15rem;
    box-sizing: border-box;
    background: #ebebeb;
    font-size: .14rem;
    color: #111
}

.g-pop .g-pop-body .pop-input-item [class*=btn-] {
    width: .9rem;
    right: .1rem;
    line-height: .3rem;
    padding: 0 .1rem;
    box-sizing: border-box;
    border-radius: .04rem;
    font-size: .14rem
}

.g-pop .g-pop-body .pop-input-item .pic-code-box {
    right: .1rem;
    display: inline-block;
    width: .9rem;
    height: .32rem;
    background-size: 100% auto;
    background-repeat: no-repeat
}

.g-pop .g-pop-body .pop-input-item .pic-code-box img {
    width: 100%;
    height: 100%
}

.g-pop .g-pop-foot {
    position: relative
}

.g-pop .g-pop-foot:before {
    content: " ";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: .01rem;
    border-top: .01rem solid #e4e4e4;
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    -webkit-transform: scaleY(.5);
    transform: scaleY(.5)
}

.g-pop .g-pop-foot .btn-box {
    padding: .1rem 0;
    text-align: center
}

.g-pop .g-pop-foot .btn-box [class*=btn] {
    display: inline-block;
    width: 1rem;
    height: .3rem;
    line-height: .3rem;
    border-radius: .02rem;
    margin: 0 .1rem;
    overflow: hidden;
    text-align: center
}

.g-pop .g-pop-foot .btn-box [class*=btn]:first-child:before {
    display: none
}

.g-pop .g-pop-foot .btn-box .btn-loading-box {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    width: .2rem
}

.g-pop .g-pop-foot .btn-box .g-loading {
    -webkit-transform: scale(.6);
    transform: scale(.6)
}

@media screen and (max-width: 320px) {
    .g-pop .g-pop-body .pop-input-item.is-btn input {
        padding-left:.1rem
    }
}

.picker-container {
    z-index: 10001;
    width: 100%
}

.picker-container,.picker-container *,.picker-container :after,.picker-container :before {
    box-sizing: border-box
}

.picker-container .picker-inner {
    position: relative;
    
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    height: 100%;
    padding: 0 20px;
    font-size: 1.2em;
    -webkit-mask-box-image: linear-gradient(0deg,transparent,transparent 5%,#fff 20%,#fff 80%,transparent 95%,transparent)
}

.picker-container .picker-column {
    f-webkit-box-flex: 1;
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    position: relative;
    height: 100%;
    overflow: hidden;
    text-align: center
}

.picker-container .picker-column .picker-scroller {
    transition: .3s;
    transition-timing-function: ease-out
}

.picker-container .picker-column .picker-item {
    position: relative;
    padding: 0 10px;
    white-space: nowrap;
    color: #999;
    overflow: hidden;
    text-overflow: ellipsis
}

.picker-container .picker-column .picker-item.picker-item-selected {
    color: #222
}

.picker-container .picker-highlight {
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    pointer-events: none
}

.picker-container .picker-highlight:after,.picker-container .picker-highlight:before {
    content: " ";
    position: absolute;
    left: 0;
    right: auto;
    display: block;
    width: 100%;
    height: 1px;
    background-color: #d9d9d9;
    -webkit-transform: scaleY(.5);
    transform: scaleY(.5)
}

.picker-container .picker-highlight:before {
    top: 0;
    bottom: auto
}

.picker-container .picker-highlight:after {
    bottom: 0;
    top: auto
}

.error-info-box {
    padding-top: 1.2rem
}

.error-info-box .error-info {
    margin-bottom: .2rem;
    text-align: center;
    font-size: .16rem
}

.error-info-box .error-info-big {
    margin-bottom: .2rem;
    font-size: .22rem
}

.error-btn-box {
    margin: .5rem;
    text-align: center
}

.error-btn-box .btn-blue {
    display: inline-block;
    width: 1.2rem;
    line-height: .4rem;
    border-radius: .04rem;
    font-size: .14rem
}

#appview,#g_body {
    height: 100%
}

.txt-red {
    color: #f23030
}

.txt-blue {
    color: #1fbccf
}

.txt-gray {
    color: #999
}

.g-hide {
    display: none
}

input::-webkit-search-cancel-button {
    display: none
}

.g-ellips {
    -webkit-line-clamp: 1
}

.g-ellips,.g-ellips_line2 {
    
    -webkit-box-orient: vertical;
    overflow: hidden
}

.g-ellips_line2 {
    -webkit-line-clamp: 2
}

.g-ml10 {
    margin-left: .1rem
}

.g-bg-white {
    background-color: #fff
}

.g-flex {
    
    display: -ms-flexbox;
    display: flex
}

.g-col-auto {
    -webkit-box-flex: 1;
    flex: 1;
    -ms-flex: 1
}

.g-vertical-center {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center
}

.g-text-blue {
    color: #1fbccf
}

.g-search-bar {
    
    display: -ms-flexbox;
    display: flex;
    margin: 0 .07rem
}

.g-search-bar .input-block {
    position: relative;
    padding-left: .36rem;
    margin: .1rem 0
}

.g-search-bar .g-icon_search {
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    left: .1rem
}

.input-block {
    
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 1;
    flex: 1;
    -ms-flex: 1;
    height: .36rem;
    border-radius: .18rem;
    overflow: hidden;
    background-color: #fff
}

.g-icon_search {
    width: .18rem;
    height: .18rem;
    background: #fff url('https://sh-user.51alm.com/asserts/icon_sprites.png') no-repeat;
    background-position: -.36rem -.5rem;
    background-size: 1.57rem 1.25rem
}

.g-search-bar input {
    margin-right: .1rem
}

.g-search-bar input::-webkit-input-placeholder {
    font-size: .12rem;
    color: #ccc
}

.g-search-bar input::-moz-placeholder {
    font-size: .12rem;
    color: #ccc
}

.g-search-bar input:-ms-input-placeholder {
    font-size: .12rem;
    color: #ccc
}

.search-entrance {
    line-height: .36rem;
    color: #999;
    font-size: .12rem
}

.g-no-more {
    position: relative;
    height: .3rem;
    text-align: center;
    color: #999
}

.g-no-more .line-box {
    position: relative;
    position: absolute;
    left: 6%;
    right: 6%;
    top: 50%;
    height: 0
}

.g-no-more .line-box:before {
    content: " ";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: .01rem;
    border-top: .01rem solid #e4e4e4;
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    -webkit-transform: scaleY(.5);
    transform: scaleY(.5)
}

.g-no-more .text {
    background: #ebebeb;
    padding: 0 .2rem;
    position: relative;
    z-index: 1;
    display: inline-block;
    line-height: .3rem;
    font-size: .12rem
}

.g-preview-wrap {
    box-sizing: border-box;
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: 48%;
    overflow: hidden
}

.g-preview-wrap img {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    width: 100%;
    height: 100%
}

.g-scroll-box {
    overflow: hidden
}

.g-scroll-box-x {
    white-space: nowrap;
    overflow-x: auto
}

.index-wrap {
    height: 100%;
    overflow: hidden
}

.index-wrap .fix-wrap {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 100;
    background: #ebebeb
}

.index-list {
    position: relative;
    z-index: 1;
    height: 100%;
    box-sizing: border-box;
    overflow: auto;
    -webkit-overflow-scrolling: touch
}

.index-list.withorder {
    padding-top: 2.07rem
}

.index-list .search-no-result {
    background-image: url('https://sh-user.51alm.com/asserts/bg-no-result-white.png')
}

.index-list-mask {
    z-index: 80
}

.index-order-wrap {
    background-color: #fff;
    margin: .1rem .07rem 0;
    box-shadow: 0 .01rem .03rem .01rem rgba(0,0,0,.2)
}

.index-order-col {

    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    box-sizing: border-box;
    min-height: .4rem;
    padding: .1rem
}

.index-order-col span {
    font-size: .14rem;
    color: #111;
    font-weight: 600;
    padding-right: .1rem
}

.index-order-wrap .g-text-blue {
    font-size: .14rem
}

.index-order-wrap .address-info {
    padding-top: .02rem;
    padding-bottom: .02rem;
    border-top: .01rem solid #e4e4e4;
    color: #ccc
}

.index-order-col.address-info span {
    font-size: .12rem;
    margin-right: .05rem;
    color: #ccc;
    font-weight: 400
}

.index-order-col.address-info span:first-child {
    display: inline-block;
    width: 1.2rem
}

.index-order-col.address-info span:last-child {
    margin-right: 0
}

.index-order-wrap p {
    -webkit-box-orient: vertical;

    -webkit-line-clamp: 1;
    overflow: hidden;
    line-height: 1.2;
    height: .16rem;
    margin-top: .08rem;
    margin-bottom: .08rem
}

.btn-order-detail {
    font-size: .12rem;
    color: #fff;
    height: .3rem;
    line-height: .3rem;
    min-width: .5rem;
    padding-left: .1rem;
    padding-right: .1rem;
    margin-left: .1rem;
    text-align: center;
    background-color: #1fbccf;
    border-radius: .02rem
}

.index-sortbar {
    background-color: #fff;
    position: relative;
    height: .4rem
}

.index-sortbar:after {
    content: " ";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: .01rem;
    border-bottom: .01rem solid #e4e4e4;
    border-radius: .04rem;
    -webkit-transform-origin: 0 100%;
    transform-origin: 0 100%;
    -webkit-transform: scaleY(.5);
    transform: scaleY(.5)
}

.index-sortbar .devider {
    position: relative;
    position: absolute;
    top: 0;
    left: 50%;
    height: 100%;
    width: 0;
    font-size: 0
}

.index-sortbar .devider:before {
    content: " ";
    position: absolute;
    left: 0;
    top: 0;
    width: .01rem;
    height: 100%;
    border-left: .01rem solid #e4e4e4;
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    -webkit-transform: scaleX(.5);
    transform: scaleX(.5)
}

.index-sortbar .sort {
    display: inline-block;
    box-sizing: border-box;
    width: 50%;
    height: .4rem;
    line-height: .4rem;
    text-align: center;
    font-size: .12rem;
    color: #999
}

.index-sortbar .sort:first-child {
    border: none
}

.g-icon_arrow {
    display: inline-block;
    width: .17rem;
    height: .1rem;
    background: url('https://sh-user.51alm.com/asserts/icon_sprites.png') no-repeat;
    background-position: -.69rem -1.06rem;
    background-size: 1.57rem 1.25rem
}

.g-icon_arrowup {
    transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -webkit-transform: rotate(180deg)
}

.index-sortbar .droplist {
    position: absolute;
    z-index: 200;
    top: .4rem;
    left: 0;
    right: 0;
    background-color: #fff
}

.index-sortbar .mask {
    position: absolute;
    top: .4rem;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: rgba(0,0,0,.5);
    z-index: 100
}

.index-sortbar .droplist .sort {
    position: relative;
    display: block;
    width: 100%;
    text-align: left;
    padding-left: 14vw;
    line-height: .4rem;
    height: .4rem;
    border-left: none
}

.index-sortbar .droplist .sort:after {
    content: " ";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: .01rem;
    border-bottom: .01rem solid #e4e4e4;
    border-radius: .04rem;
    -webkit-transform-origin: 0 100%;
    transform-origin: 0 100%;
    -webkit-transform: scaleY(.5);
    transform: scaleY(.5)
}

.index-sortbar .droplist .sort:first-child {
    border: none
}

.index-sortbar .droplist .current {
    color: #1fbccf
}

.index-sortbar .g-icon_arrow {
    margin-left: .04rem
}

.index-list .list-item {
    background-color: #fff;
    margin-bottom: .1rem
}

.index-list .list-item:last-child {
    margin-bottom: 0
}

.index-list .prod-info {
    padding: .1rem .07rem
}

.index-list .img-block {
    position: relative;
    width: .7rem;
    height: .7rem;
    margin-right: .1rem
}

.index-list .img-block:after {
    content: " ";
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    width: 200%;
    height: 200%;
    border: .01rem solid #e4e4e4;
    border-radius: 0;
    -webkit-border-radius: 0;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    transform-origin: 0 0;
    -webkit-transform-origin: 0 0;
    transform: scale(.5);
    -webkit-transform: scale(.5)
}

.index-list .img-lab {
    display: inline-block;
    width: .38rem;
    height: .38rem;
    position: absolute;
    top: 0;
    left: 0
}

.bg-icon_offen {
    background: url('https://sh-user.51alm.com/asserts/icon_sprites.png') -5px -5px;
    background-size: 157px 125px
}

.bg-icon_once {
    background: url('https://sh-user.51alm.com/asserts/icon_sprites.png') -50px -5px;
    background-size: 157px 125px
}

.index-list img {
    width: .7rem;
    height: .7rem
}

.index-list h2 {
    padding-top: .05rem;
    padding-bottom: .04rem;
    font-size: .14rem;
    color: #111
}

.index-list .distance {
    color: #999;
    font-size: .11rem;
    line-height: .24rem;
    padding-left: .1rem
}

.index-list .add-info {
    position: relative;
    padding-left: .15rem;
    box-sizing: border-box;
    font-size: .12rem;
    color: #999;
    margin-top: .07rem;
    height: .17rem;
    line-height: .17rem
}

.index-list .add-info .add-text {
    padding-left: .05rem
}

.index-list .add-info .bg-icon_address,.index-list .add-info .bg-icon_order {
    position: absolute;
    left: 0;
    top: 0
}

.bg-icon_address {
    background: url('https://sh-user.51alm.com/asserts/icon_sprites.png');
    background-position: -1.2rem -.28rem;
    background-size: 1.57rem 1.25rem
}

.bg-icon_address,.bg-icon_order {
    display: inline-block;
    width: .15rem;
    height: .15rem
}

.bg-icon_order {
    background: url('https://sh-user.51alm.com/asserts/icon_sprites.png');
    background-position: -.83rem -.81rem;
    background-size: 1.57rem 1.25rem
}

.bg-icon_phone {
    display: inline-block;
    width: .17rem;
    height: .17rem;
    background-image: url('https://sh-user.51alm.com/asserts/icon_sprites.png');
    background-position: -.02rem -.33rem;
    background-size: 1.06rem .85rem
}

.index-list .promo-info {
    padding-bottom: .1rem;
    padding-left: .07rem;
    padding-right: .07rem;
    font-size: .12rem;
    color: #111;
    line-height: .16rem
}

.index-list .icon_box {
    width: .16rem;
    margin-left: .54rem;
    margin-right: .1rem
}

.bg-icon_promon {
    width: .16rem;
    height: .16rem;
    background-image: url('https://sh-user.51alm.com/asserts/icon_sprites.png');
    background-position: -.94rem -.57rem;
    background-size: 1.57rem 1.25rem
}

.bg-icon_promo {
    width: .16rem;
    height: .16rem;
    background: url('https://sh-user.51alm.com/asserts/icon_sprites.png');
    background-position: -.59rem -.81rem;
    background-size: 1.57rem 1.25rem
}

.index-list dfn {
    margin-left: .03rem;
    margin-right: .03rem
}

.index-list .promo-pric {
    font-size: .14rem;
    color: #f23030
}

.index-list .order-block {
    position: relative
}

.index-list .order-block:before {
    content: " ";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: .01rem;
    border-top: .01rem solid #e4e4e4;
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    -webkit-transform: scaleY(.5);
    transform: scaleY(.5)
}

.index-list .tel-box {
    width: 37%;
    height: .4rem
}

.index-list .btn-order,.index-list .tel-box {
    display: inline-block;
    vertical-align: middle;
    text-align: center
}

.index-list .btn-order {
    width: 63%;
    background-color: #f23030;
    color: #fff;
    font-size: .14rem;
    line-height: .4rem
}

.g-model-title {
    position: relative;
    padding: 0 .07rem;
    background-color: #fff;
    line-height: .4rem;
    color: #999;
    font-size: .14rem
}

.g-model-title:after {
    content: " ";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: .01rem;
    border-bottom: .01rem solid #e4e4e4;
    border-radius: .04rem;
    -webkit-transform-origin: 0 100%;
    transform-origin: 0 100%;
    -webkit-transform: scaleY(.5);
    transform: scaleY(.5)
}

.shop-base-info {

    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    background-color: #fff;
    padding: .15rem 0 .15rem .07rem;
    color: #1fbccf
}

.shop-base-info h2 {
    font-size: .14rem;
    color: #111;
    margin-bottom: .1rem;
    font-weight: 600
}

.shop-base-info .left {
    position: relative;
    padding-right: .05rem
}

.shop-base-info .left:after {
    content: " ";
    position: absolute;
    top: 0;
    right: 0;
    width: .01rem;
    height: 100%;
    border-right: .01rem solid #e4e4e4;
    -webkit-transform-origin: 100% 0;
    transform-origin: 100% 0;
    -webkit-transform: scaleX(.5);
    transform: scaleX(.5)
}

.shop-base-info .left .bg-icon_position {
    vertical-align: -.01rem;
    margin-right: .05rem
}

.shop-base-info .tel-box {
    position: relative;
    width: .6rem;
    height: .4rem
}

.shop-base-info .bg-icon_phone {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%,-50%);
    transform: translate(-50%,-50%);
    width: .17rem
}

.bg-icon_position {
    display: inline-block;
    vertical-align: middle;
    width: .11rem;
    height: .13rem;
    background: url('https://sh-user.51alm.com/asserts/icon_sprites.png') no-repeat;
    background-position: -.49rem -1.07rem;
    background-size: 1.57rem 1.25rem
}

.address-detail {
    position: relative;
    background-color: #fff;
    color: #1fbccf;
    min-height: .3rem;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: .07rem .1rem;
    font-size: .12rem
}

.address-detail:before {
    content: " ";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: .01rem;
    border-top: .01rem solid #e4e4e4;
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    -webkit-transform: scaleY(.5);
    transform: scaleY(.5)
}

.bg-icon_arrowr {
    display: inline-block;
    width: .15rem;
    height: .11rem;
    background-image: url('https://sh-user.51alm.com/asserts/icon_sprites.png');
    background-position: -1.39rem -.05rem;
    background-size: 1.57rem 1.25rem
}

.shop-tech-list {
    margin-top: .1rem;
    background: #fff
}

.shop-tech-list .list-title {
    position: relative;
    line-height: .4rem;
    padding: 0 .07rem
}

.shop-tech-list .list-title:after {
    content: " ";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: .01rem;
    border-bottom: .01rem solid #e4e4e4;
    border-radius: .04rem;
    -webkit-transform-origin: 0 100%;
    transform-origin: 0 100%;
    -webkit-transform: scaleY(.5);
    transform: scaleY(.5)
}

.shop-tech-list .tech-list {
    padding: 0 .07rem;
    box-sizing: border-box
}

.shop-tech-list .tech-list .scroll-item {
    border-radius: .02rem
}

.shop-tech-list .tech-list .item-text {
    border-radius: 0 0 .02rem .02rem;
    z-index: 0
}

.shop-detail-list {
    background-color: #fff;
    margin-top: .1rem
}

.shop-detail-list h2 {
    font-size: .14rem;
    color: #111;
    padding: .05rem .07rem .05rem 0;
    margin-bottom: .1rem
}

.shop-detail-list dfn {
    margin: 0 .03rem
}

.shop-detail-list .list-item {
    position: relative;
    padding: .1rem .07rem
}

.shop-detail-list .list-item:before {
    content: " ";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: .01rem;
    border-top: .01rem solid #e4e4e4;
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    -webkit-transform: scaleY(.5);
    transform: scaleY(.5)
}

.shop-detail-list .list-item:first-child:before {
    display: none
}

.shop-detail-list .list-item-showmore {
    position: relative;
    line-height: .4rem;
    text-align: center;
    background: #fff;
    font-size: .14rem;
    color: #999
}

.shop-detail-list .list-item-showmore:before {
    content: " ";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: .01rem;
    border-top: .01rem solid #e4e4e4;
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    -webkit-transform: scaleY(.5);
    transform: scaleY(.5)
}

.shop-detail-list .list-item-showmore .icon-arrow-down,.shop-detail-list .list-item-showmore .icon-arrow-up {
    margin-left: .1rem
}

.shop-detail-list .img-block {
    width: .8rem;
    height: .8rem;
    margin-right: .1rem
}

.shop-detail-list img {
    width: .8rem;
    height: .8rem
}

.shop-detail-list .promo-info {
    line-height: .16rem;
    font-size: .12rem;
    color: #999
}

.shop-detail-list .icon_box {
    margin-right: .05rem
}

.shop-detail-list .price-info {
    font-size: .16rem;
    padding-top: .12rem
}

.shop-detail-list .price-n {
    color: #f23030;
    font-weight: 600;
    margin-right: .1rem
}

.shop-detail-list .price-n dfn {
    font-size: .12rem
}

.shop-detail-list .price-o {
    color: #999;
    font-size: .14rem;
    -webkit-text-decoration-line: line-through;
    text-decoration-line: line-through
}

.shop-detail-list .btn-booking {
    width: .7rem;
    height: .3rem;
    line-height: .3rem;
    font-size: .12rem;
    color: #fff;
    background-color: #f23030;
    border-radius: .02rem;
    margin-left: .1rem;
    text-align: center
}

.shop-assistant {
    margin-top: .1rem;
    background-color: #fff
}

.shop-assistant .assistant-list {
    padding: .1rem .07rem
}

.assistant {
    box-sizing: border-box;
    display: inline-block;
    float: left;
    width: .9rem;
    height: 1.2rem;
    border: .01rem solid #ccc;
    color: #999;
    font-size: .14rem;
    text-align: center;
    line-height: .3rem;
    overflow: hidden;
    margin-left: .1rem
}

.assistant-list .assistant:first-child {
    margin-left: 0
}

.assistant .img-block,.assistant img {
    width: .9rem;
    height: .9rem
}

.shop-tip {
    position: relative;
    padding: .05rem .07rem;
    font-size: .12rem;
    color: #999;
    line-height: .22rem;
    background: #fff
}

.shop-tip:before {
    content: " ";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: .01rem;
    border-top: .01rem solid #e4e4e4;
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    -webkit-transform: scaleY(.5);
    transform: scaleY(.5)
}

.shop-tip span {
    margin-right: .1rem
}

.shop-tip dfn {
    margin: 0 .03rem
}

.brand-info-wrap {
    background-color: #fff;
    color: #999;
    margin-top: .1rem
}

.brand-info-wrap .img-block {
    width: .6rem;
    height: .6rem;
    overflow: hidden;
    margin-right: .1rem
}

.brand-info-wrap img {
    width: .6rem;
    height: .6rem
}

.brand-info-wrap h2 {
    font-size: .14rem;
    color: #111;
    padding-top: .1rem
}

.brand-info-wrap .main {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: .07rem
}

.brand-info-wrap .info-block {
    min-height: .6rem
}

.shop-tag-list li {
    display: inline-block;
    margin-right: .1rem;
    margin-top: .12rem
}

.bg-icon_shield {
    display: inline-block;
    width: .15rem;
    height: .15rem;
    background: url('https://sh-user.51alm.com/asserts/icon_sprites.png') -.27rem -1.07rem;
    background-size: 1.57rem 1.25rem
}

.brand-info-wrap .bg-icon_shield {
    margin-bottom: -.03rem;
    margin-right: .03rem
}

.more-info {
    position: relative;
    padding: 0 .07rem;
    background-color: #fff;
    line-height: .44rem;
    color: #999;
    font-size: .14rem;

    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center
}

.more-info:before {
    content: " ";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: .01rem;
    border-top: .01rem solid #e4e4e4;
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    -webkit-transform: scaleY(.5);
    transform: scaleY(.5)
}

.ast-preview-wrap {
    background-color: #fff;
    padding: .1rem .15rem;
    font-size: .12rem;
    color: #999
}

.ast-preview-wrap .img-block {
    width: .8rem;
    height: .8rem;
    border-radius: 50%;
    overflow: hidden;
    margin-right: .2rem
}

.ast-preview-wrap img {
    width: .8rem;
    height: .8rem;
    border-radius: .4rem;
    overflow: hidden
}

.ast-tit {
    font-size: .14rem;
    color: #111;
    line-height: .18rem;
    font-weight: 600
}

.ast-tag {
    position: relative;
    display: inline-block;
    padding: .02rem .09rem;
    font-size: .09rem;
    color: #1fbccf;
    min-width: .5rem;
    box-sizing: border-box;
    margin-left: .15rem;
    text-align: center
}

.ast-tag:after {
    content: " ";
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    width: 200%;
    height: 200%;
    border: .01rem solid #1fbccf;
    border-radius: .18rem;
    -webkit-border-radius: .18rem;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    transform-origin: 0 0;
    -webkit-transform-origin: 0 0;
    transform: scale(.5);
    -webkit-transform: scale(.5)
}

.ast-preview-wrap p {
    padding-top: .07rem;
    padding-bottom: 0
}

.ast-preview-wrap .tit-block {
    padding-top: .14rem;
    padding-bottom: .03rem
}

.ast-preview-wrap dfn {
    margin-left: .03rem;
    margin-right: .02rem
}

.ast-des-wrap {
    background-color: #fff;
    margin-top: .1rem;
    padding-bottom: .1rem
}

.ast-des-wrap .tech-description {
    padding: 0 .07rem;
    line-height: .18rem
}

.ast-des-wrap .g-model-title:after {
    display: none
}

.ast-des-wrap .tag-list {
    padding: .02rem .07rem
}

.ast-des-wrap .tag-list .ast-tag {
    font-size: .12rem;
    margin-left: .1rem
}

.ast-des-wrap .tag-list .ast-tag:first-child {
    margin-left: 0
}

.ast-des-wrap p {
    padding: 0 .07rem .07rem;
    font-size: .14rem;
    color: #111;
    line-height: .2rem
}

.ast-stay-wrap {
    background-color: #fff;
    margin-top: .1rem
}

.ast-stay-wrap .bg-icon_clock {
    margin-right: .03rem
}

.ast-stay-wrap .bg-icon_position {
    margin-right: .05rem
}

.ext-list {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: .1rem .07rem
}

.ext-list .img-block {
    overflow: hidden;
    margin-right: .1rem
}

.ext-list .img-block,.ext-list img {
    width: .6rem;
    height: .6rem
}

.ext-list .info-block {
    position: relative;
    min-height: .6rem;
    font-size: .12rem;
    color: #999;
    padding-right: .05rem
}

.ext-list .info-block:after {
    content: " ";
    position: absolute;
    top: 0;
    right: 0;
    width: .01rem;
    height: 100%;
    border-right: .01rem solid #e4e4e4;
    -webkit-transform-origin: 100% 0;
    transform-origin: 100% 0;
    -webkit-transform: scaleX(.5);
    transform: scaleX(.5)
}

.ext-list h2 {
    font-size: .14rem;
    color: #111;
    padding-top: .02rem;
    margin-bottom: .03rem
}

.bg-icon_clock {
    display: inline-block;
    width: .13rem;
    height: .13rem;
    background: url('https://sh-user.51alm.com/asserts/icon_sprites.png');
    background-position: -.04rem -.9rem;
    background-size: 1.35rem 1.07rem;
    margin-bottom: -.01rem
}

.ext-list .info-row {
    padding-top: .05rem
}

.ext-list .bg-icon_phone {
    margin-left: .1rem;
    margin-right: .04rem
}

.prod-info-wrap {
    background-color: #fff
}

.prod-info-wrap h2 {
    font-size: .14rem;
    color: #111;
    font-weight: 600;
    line-height: .18rem;
    padding: .08rem .07rem
}

.prod-info-wrap h2 dfn {
    margin: -.02rem .05rem 0
}

.prod-info-wrap .price-info {
    padding: 0 .07rem .1rem
}

.prod-info-wrap .price-n {
    color: #f23030;
    font-weight: 600;
    font-size: .14rem
}

.prod-info-wrap .price-o {
    color: #999;
    font-size: .12rem;
    -webkit-text-decoration-line: line-through;
    text-decoration-line: line-through;
    margin-left: .15rem
}

.prod-info-wrap .ext-info {
    padding: 0 .07rem .08rem
}

.prod-info-wrap .info-row {
    margin-right: .15rem;
    display: inline-block
}

.bg-icon_house {
    display: inline-block;
    width: .15rem;
    height: .15rem;
    background: url('https://sh-user.51alm.com/asserts/icon_sprites.png') -120px -79px;
    background-size: 157px 125px;
    margin-bottom: -.02rem
}

.prod-info-wrap .shop-base-info {
    padding: .05rem .07rem;
    border-top: .01rem solid #e4e4e4;
    line-height: .15rem
}

.productdetail_container {
    padding-bottom: .68rem
}

.prod-detail-wrap {
    background-color: #fff;
    margin-top: .1rem;
    padding: .1rem .07rem
}

.prod-detail-wrap li {
    padding: 0 .07rem .1rem;
    font-size: .12rem;
    color: #111;
    line-height: .2rem
}

.prod-detail-wrap .item-tit {
    font-size: .12rem;
    line-height: .32rem;
    white-space: nowrap;
    overflow: hidden;
    color: #999
}

.prod-detail-wrap .img-block {
    box-sizing: border-box;
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: 43%;
    margin-bottom: .1rem
}

.prod-detail-wrap .item-main .img-block:last-child {
    margin-bottom: 0
}

.prod-detail-wrap img {
    width: 100%;
    height: 100%
}

.prod-detail-wrap p {
    padding: .05rem 0
}

.prod-detail-wrap p b {
    color: #999;
    font-weight: 400
}

.warning-wrap {
    background-color: #fff;
    margin-top: .1rem
}

.warning-tit {
    color: #f23030;
    border-bottom: none;
    line-height: .26rem;
    padding-top: .04rem
}

.warning-wrap .main {
    padding: 0 .07rem .06rem;
    font-size: .12rem;
    color: #111;
    line-height: .2rem
}

.btn-order-fix .btn-order {
    display: block;
    color: #fff;
    font-size: .14rem;
    background-color: #1fbccf;
    line-height: .48rem;
    text-align: center;
    border-radius: .03rem
}

.btn-order-fix {
    position: relative;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    padding: .1rem;
    background: #fff
}

.btn-order-fix:before {
    content: " ";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: .01rem;
    border-top: .01rem solid #e4e4e4;
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    -webkit-transform: scaleY(.5);
    transform: scaleY(.5)
}

.search-wrap {
    display: -ms-flexbox;
    display: flex;

    -webkit-box-orient: vertical;
    -ms-flex-direction: column;
    flex-direction: column;
    height: 100%
}

.search-wrap .flex-item,.search-wrap .mem-wrap {
    -ms-flex: 1;
    flex: 1;
    -webkit-box-flex: 1;
    overflow: hidden
}

.search-wrap .mem-wrap {
    background: #fff
}

.btn-serch-cancel {
    color: #1fbccf;
    line-height: .36rem;
    padding: .1rem;
    font-size: .14rem
}

.search-history-top {
    display: inline-block;
    background-color: #ebebeb;
    width: 100%
}

.mem-tit {
    line-height: .4rem;
    padding: 0 .07rem;
    font-size: .12rem;
    color: #999
}

.mem-tit .left {
    float: left;
    color: #999
}

.mem-tit .right {
    float: right;
    padding: 0 .1rem 0 .3rem;
    color: #1fbccf
}

.search-no-result {
    padding: 1.5rem 0 0;
    background: url('https://sh-user.51alm.com/asserts/bg-no-result.png');
    background-size: .65rem .65rem;
    background-position: center .7rem;
    background-repeat: no-repeat;
    text-align: center;
    line-height: .22rem;
    font-size: .14rem;
    color: #999
}

.mem-tag-list {
    padding: 0 .07rem;
    clear: both
}

.mem-tag-list li {
    position: relative;
    box-sizing: border-box;
    display: inline-block;
    padding: 0 .1rem;
    min-width: .6rem;
    line-height: .28rem;
    font-size: .12rem;
    color: #111;
    margin-right: .1rem;
    margin-bottom: .1rem;
    text-align: center;
    max-width: 100%;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden
}

.mem-tag-list li:after {
    content: " ";
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    width: 200%;
    height: 200%;
    border: .01rem solid #ccc;
    border-radius: .3rem;
    -webkit-border-radius: .3rem;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    transform-origin: 0 0;
    -webkit-transform-origin: 0 0;
    transform: scale(.5);
    -webkit-transform: scale(.5)
}

.brand-shop-info {
    background-color: #fff
}

.brand-shop-info .shop-name-box {
    text-align: center;
    margin-top: -.4rem
}

.shop-name-box .shop-img {
    position: relative;
    height: .8rem;
    width: .8rem;
    margin: 0 auto;
    background-color: #fff;
    border-radius: 50%;
    border: .01rem solid #fff;
    box-sizing: border-box;
    overflow: hidden
}

.shop-img img {
    height: 100%
}

.shop-name-box .shop-name-txt {
    padding: 0 .05rem;
    margin: .1rem 0 0;
    color: #111;
    font-size: .16rem
}

.brand-shop-info .shop-badge {
    padding: .2rem .05rem;
    text-align: center
}

.brand-shop-info .shop-badge span {
    margin-right: .1rem;
    font-size: .12rem;
    color: #999
}

.brand-shop-info .shop-badge i {
    margin-right: .03rem;
    vertical-align: middle
}

.brand-shop-section .brand-shop-title {
    padding: 0 .07rem;
    height: .44rem;
    line-height: .44rem;
    font-size: .14rem;
    color: #111;
    border-bottom: .01rem solid #e4e4e4
}

.brand-shop-section .shop-list-box {
    overflow: hidden
}

.brand-shop-section .shop-list-title {
    margin: .05rem 0
}

.brand-shop-section .shop-list-address {
    margin: .1rem 0;
    padding-right: .1rem;
    font-size: .14rem;
    color: #999;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden
}

.brand-shop-section .shop-list-price {
    margin: .05rem 0;
    font-size: .17rem;
    color: #f23023;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden
}

.brand-shop-section .shop-list-price dfn {
    margin: 0;
    font-size: .14rem
}

.brand-shop-section .shop-list-price i {
    margin: 0 0 0 .02rem
}

.shop-detail-list .list-title {
    font-weight: 700;
    font-size: .14rem;
    color: #111;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden
}

.shop-detail-list .list-title-distance {
    color: #999;
    font-size: .13rem;
    margin-left: .05rem
}

.brand-envir-section {
    background-color: #fff;
    margin-top: .1rem;
    overflow: hidden
}

.brand-envir-section .envir-list-title {
    padding: 0 .07rem;
    height: .44rem;
    line-height: .44rem;
    font-size: .14rem;
    color: #111;
    border-bottom: .01rem solid #e4e4e4
}

.brand-envir-section .envir-list-box {
    overflow-x: scroll;
    padding: .1rem .02rem;
    box-sizing: border-box
}

.brand-envir-section .envir-list-img {
    height: .9rem;
    min-width: 1.3rem;
    text-align: center;
    overflow: hidden;
    box-sizing: border-box
}

.brand-envir-section .envir-list-img img {
    height: .9rem;
    width: 1.2rem
}

.branddetail_container img {
    width: 100%;
    height: auto
}

.brand-intro-section {
    background-color: #fff;
    margin-top: .1rem;
    overflow: hidden
}

.brand-intro-section .brand-intro-title {
    padding: 0 .07rem;
    height: .44rem;
    line-height: .44rem;
    font-size: .14rem;
    color: #111;
    border-bottom: .01rem solid #e4e4e4
}

.brand-intro-section .brand-intro-block {
    padding: .1rem .07rem;
    border-bottom: .01rem solid #e4e4e4
}

.brand-intro-box {
    line-height: .18rem;
    padding: .05rem .07rem
}

.brand-intro-box p {
    padding: .05rem
}

.bg-icon_crown {
    background: url('https://sh-user.51alm.com/asserts/icon_sprites.png') -95px -31px;
    background-size: 157px 125px
}

.bg-icon_crown,.bg-icon_heart {
    display: inline-block;
    width: .16rem;
    height: .16rem
}

.bg-icon_heart {
    background: url('https://sh-user.51alm.com/asserts/icon_sprites.png') -64px -50px;
    background-size: 157px 125px
}

.bg-icon_book {
    display: inline-block;
    width: .16rem;
    height: .16rem;
    background: url('https://sh-user.51alm.com/asserts/icon_sprites.png') -95px -5px;
    background-size: 157px 125px
}

.brand-intro-block .brand-intro-subtitle {
    font-size: .14rem;
    color: #111
}

.brand-intro-subtitle span {
    margin-right: .1rem;
    vertical-align: sub
}

.brand-intro-block .brand-intro-info {
    padding: .15rem 0 .1rem;
    font-size: .14rem;
    line-height: .14rem;
    color: #999
}

.brand-intro-info .brand-txt {
    min-width: 1.4rem;
    width: 50%;
    padding-left: .26rem;
    box-sizing: border-box
}

.brand-intro-info .g-flex {
    margin: .1rem 0 0;
    box-sizing: border-box
}

.brand-intro-info .g-flex:first-child {
    margin: 0
}

.brand-intro-info .brand-intro-placeholder {
    width: .26rem;
    height: .14rem;
    display: inline-block
}

.brand-intro-info .brand-intro-txt {
    line-height: .26rem
}

.brand-intro-info .brand-intro-img {
    margin: .15rem 0 0;
    box-sizing: border-box;
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: 43%
}

.brand-intro-info .brand-intro-img img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%
}

.pop-wrap {
    position: fixed;
    top: 0;
    z-index: 1100;
    overflow: hidden
}

.pop-drawer,.pop-wrap {
    bottom: 0;
    left: 0;
    right: 0
}

.pop-drawer {
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -ms-flex-direction: column;
    flex-direction: column;
    position: absolute;
    z-index: 1200;
    max-height: 4.8rem;
    min-height: 3rem;
    overflow-y: hidden;
    background: #fff
}

.pop-drawer .flex-item {
    -ms-flex: 1;
    flex: 1;
    -webkit-box-flex: 1;
    overflow: hidden
}

.pop-drawer .pop-title {
    position: relative;
    height: .4rem;
    text-align: center
}

.pop-drawer .pop-title:after {
    content: " ";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: .01rem;
    border-bottom: .01rem solid #e4e4e4;
    border-radius: .04rem;
    -webkit-transform-origin: 0 100%;
    transform-origin: 0 100%;
    -webkit-transform: scaleY(.5);
    transform: scaleY(.5)
}

.pop-drawer .pop-title.no-border:after {
    display: none
}

.pop-drawer .pop-title .name-box {
    position: relative
}

.pop-drawer .pop-title .name-box .after,.pop-drawer .pop-title .name-box .pre {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    width: .5rem;
    height: 0
}

.pop-drawer .pop-title .name-box .after:before,.pop-drawer .pop-title .name-box .pre:before {
    content: " ";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: .01rem;
    border-top: .01rem solid #e4e4e4;
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    -webkit-transform: scaleY(.5);
    transform: scaleY(.5)
}

.pop-drawer .pop-title .name {
    padding: 0 .1rem;
    line-height: .4rem;
    font-size: .12rem;
    color: #999
}

.pop-drawer .pop-title.side-title {
    position: relative;
    text-align: left
}

.pop-drawer .pop-title.side-title:after {
    content: " ";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: .01rem;
    border-bottom: .01rem solid #e4e4e4;
    border-radius: .04rem;
    -webkit-transform-origin: 0 100%;
    transform-origin: 0 100%;
    -webkit-transform: scaleY(.5);
    transform: scaleY(.5)
}

.pop-drawer .pop-title.side-title .title {
    line-height: .4rem;
    padding: 0 .07rem
}

.pop-drawer .pop-title.side-title .title .txt-red {
    padding-left: .05rem
}

.pop-drawer .pop-title .btn-close {
    position: absolute;
    right: 0;
    top: 0;
    z-index: 1
}

.pop-drawer .pop-content {
    -ms-flex: 1;
    flex: 1;
    -webkit-box-flex: 1;
    overflow: hidden;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch
}

.pop-drawer .pop-content .pop-content-scroll-wrap {
    height: 100.1%
}

.pop-drawer .pop-footer .pop-btn-footer-box {
    position: relative;
    padding: .1rem
}

.pop-drawer .pop-footer .pop-btn-footer-box:before {
    content: " ";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: .01rem;
    border-top: .01rem solid #e4e4e4;
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    -webkit-transform: scaleY(.5);
    transform: scaleY(.5)
}

.pop-drawer .pop-footer .btn-red {
    width: 100%;
    height: .48rem;
    line-height: .48rem;
    border-radius: .03rem
}

.g-pop .g-pop-foot .btn-box .pop-alert-btn,.g-pop .g-pop-foot .btn-box .pop-alert-btn-blue {
    width: 50%;
    height: .4rem;
    line-height: .4rem;
    margin: -.1rem 0;
    text-align: center
}

.g-pop .g-pop-foot .btn-box .pop-alert-btn+.pop-alert-btn,.g-pop .g-pop-foot .btn-box .pop-alert-btn+.pop-alert-btn-blue,.g-pop .g-pop-foot .btn-box .pop-alert-btn-blue+.pop-alert-btn,.g-pop .g-pop-foot .btn-box .pop-alert-btn-blue+.pop-alert-btn-blue {
    position: relative
}

.g-pop .g-pop-foot .btn-box .pop-alert-btn+.pop-alert-btn-blue:before,.g-pop .g-pop-foot .btn-box .pop-alert-btn+.pop-alert-btn:before,.g-pop .g-pop-foot .btn-box .pop-alert-btn-blue+.pop-alert-btn-blue:before,.g-pop .g-pop-foot .btn-box .pop-alert-btn-blue+.pop-alert-btn:before {
    content: " ";
    position: absolute;
    left: 0;
    top: 0;
    width: .01rem;
    height: 100%;
    border-left: .01rem solid #e4e4e4;
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    -webkit-transform: scaleX(.5);
    transform: scaleX(.5)
}

.g-pop .g-pop-foot .btn-box .pop-alert-btn-blue {
    background: #1fbccf;
    color: #fff
}

.mui-switch {
    width: .52rem;
    height: .31rem;
    position: relative;
    border: .01rem solid #dfdfdf;
    background-color: #fdfdfd;
    box-shadow: inset 0 0 0 0 #dfdfdf;
    border-radius: .2rem;
    border-top-left-radius: .2rem;
    border-top-right-radius: .2rem;
    border-bottom-left-radius: .2rem;
    border-bottom-right-radius: .2rem;
    background-clip: content-box;
    display: inline-block;
    -webkit-appearance: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    outline: none
}

.mui-switch:before {
    content: "";
    width: .29rem;
    height: .29rem;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: .2rem;
    border-top-left-radius: .2rem;
    border-top-right-radius: .2rem;
    border-bottom-left-radius: .2rem;
    border-bottom-right-radius: .2rem;
    background-color: #fff;
    box-shadow: 0 .01rem .03rem rgba(0,0,0,.4)
}

.mui-switch:checked {
    border-color: #64bd63;
    box-shadow: inset 0 0 0 .16rem #64bd63;
    background-color: #64bd63
}

.mui-switch:checked:before {
    left: .21rem
}

.mui-switch.mui-switch-animbg {
    transition: background-color .4s ease
}

.mui-switch.mui-switch-animbg:before {
    transition: left .3s
}

.mui-switch.mui-switch-animbg:checked {
    box-shadow: inset 0 0 0 0 #dfdfdf;
    background-color: #64bd63;
    transition: border-color .4s,background-color .4s ease
}

.mui-switch.mui-switch-animbg:checked:before {
    transition: left .3s
}

.mui-switch.mui-switch-anim {
    transition: border .4s cubic-bezier(0,0,0,1),box-shadow .4s cubic-bezier(0,0,0,1)
}

.mui-switch.mui-switch-anim:before {
    transition: left .3s
}

.mui-switch.mui-switch-anim:checked {
    box-shadow: inset 0 0 0 16px #64bd63;
    background-color: #64bd63;
    transition: border .4s ease,box-shadow .4s ease,background-color 1.2s ease
}

.mui-switch.mui-switch-anim:checked:before {
    transition: left .3s
}

[class*=icon-] {
    display: inline-block;
    background-repeat: no-repeat;
    background-size: contain
}

.icon-checkbox,.icon-checkbox-checked {
    width: .17rem;
    height: .17rem;
    background-image: url('https://sh-user.51alm.com/asserts/icon-checkbox.png')
}

.icon-checkbox-checked {
    background-image: url('https://sh-user.51alm.com/asserts/icon-checkbox-checked.png')
}

.icon-people {
    width: .15rem;
    height: .16rem;
    background-image: url('https://sh-user.51alm.com/asserts/icon-people.png')
}

.icon-radio,.icon-radio-checked {
    vertical-align: -.04rem;
    width: .17rem;
    height: .17rem;
    background-image: url('https://sh-user.51alm.com/asserts/icon-checkbox.png')
}

.icon-radio-checked {
    background-image: url('https://sh-user.51alm.com/asserts/icon-radiobox-checked.png')
}

.icon-arrow-down,.icon-arrow-right,.icon-arrow-up {
    width: .07rem;
    height: .11rem;
    background-image: url('https://sh-user.51alm.com/asserts/icon-arrow-right.png')
}

.icon-arrow-up {
    -webkit-transform: rotate(-90deg);
    transform: rotate(-90deg)
}

.icon-arrow-down {
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg)
}

.icon-edit {
    width: .14rem;
    height: .14rem;
    background-image: url('https://sh-user.51alm.com/asserts/icon-edit.png')
}

.btn-close,.icon-close {
    display: inline-block;
    background-repeat: no-repeat;
    background-size: contain;
    width: .4rem;
    height: .4rem;
    background-image: url('https://sh-user.51alm.com/asserts/icon-close.png')
}

.icon-day {
    background-image: url('https://sh-user.51alm.com/asserts/icon-day.png')
}

.icon-day,.icon-night {
    width: .17rem;
    height: .17rem
}

.icon-night {
    background-image: url('https://sh-user.51alm.com/asserts/icon-night.png')
}

.icon-position {
    width: .18rem;
    height: .18rem;
    background-image: url('https://sh-user.51alm.com/asserts/icon-location.png')
}

.icon-phone {
    background-image: url('https://sh-user.51alm.com/asserts/icon-phone.png')
}

.icon-phone,.icon-success {
    width: .18rem;
    height: .18rem
}

.icon-success {
    background-image: url('https://sh-user.51alm.com/asserts/icon-success.png')
}

.icon-recharge {
    width: .22rem;
    height: .22rem;
    background-image: url('https://sh-user.51alm.com/asserts/icon-wallet.png')
}

.icon-shop {
    width: .14rem;
    height: .13rem;
    background-image: url('https://sh-user.51alm.com/asserts/icon-shop.png')
}

.icon-alarm {
    background-image: url('https://sh-user.51alm.com/asserts/icon-alarm.png');
    width: .12rem;
    height: .12rem;
    background-image: url('https://sh-user.51alm.com/asserts/icon-alarm.png')
}

.icon-customer {
    width: .12rem;
    height: .12rem;
    background-image: url('https://sh-user.51alm.com/asserts/icon-customer.png')
}

.icon-project {
    width: .12rem;
    height: .12rem;
    background-image: url('https://sh-user.51alm.com/asserts/icon-project.png')
}

.icon-allorder {
    width: .17rem;
    height: .17rem;
    background-image: url('https://sh-user.51alm.com/asserts/icon-allorder.png')
}

.icon-unconsumed {
    width: .22rem;
    height: .22rem;
    background-image: url('https://sh-user.51alm.com/asserts/icon-unconsumed.png')
}

.icon-service {
    width: .22rem;
    height: .22rem;
    background-image: url('https://sh-user.51alm.com/asserts/icon-service.png')
}

.icon-completed {
    width: .22rem;
    height: .22rem;
    background-image: url('https://sh-user.51alm.com/asserts/icon-completed.png')
}

.icon-mycoupon {
    background-image: url('https://sh-user.51alm.com/asserts/icon-mycoupon.png')
}

.icon-logout,.icon-join,.icon-mycoupon {
    width: .22rem;
    height: .22rem
}

.icon-join {
    background-image: url('https://sh-user.51alm.com/asserts/icon-join.png')
}

.icon-logout {
    background-image: url('https://shenghe-static.oss-cn-shanghai.aliyuncs.com/logout_icon.png')
}

.icon-nextday {
    width: .25rem;
    height: .13rem;
    background-image: url('https://sh-user.51alm.com/asserts/icon-nextday.png')
}

.icon-selected-coupon {
    width: .5rem;
    height: .4rem;
    background-image: url('https://sh-user.51alm.com/asserts/icon-selected-coupon.png')
}

.icon-balance {
    width: .23rem;
    height: .23rem;
    background-image: url('https://sh-user.51alm.com/asserts/icon-balance.png')
}

.icon-wechat {
    width: .23rem;
    height: .23rem;
    background-image: url('https://sh-user.51alm.com/asserts/icon-wechat.png')
}

.icon-total {
    width: .15rem;
    height: .15rem;
    background-image: url('https://sh-user.51alm.com/asserts/icon-total.png')
}

.line-list .row {
    position: relative
}

.line-list .row:after {
    content: " ";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: .01rem;
    border-bottom: .01rem solid #e4e4e4;
    border-radius: .04rem;
    -webkit-transform-origin: 0 100%;
    transform-origin: 0 100%;
    -webkit-transform: scaleY(.5);
    transform: scaleY(.5)
}

.line-list .row:last-child:after {
    display: none
}

.tel-box {
    position: relative
}

.tel-box .bg-icon_phone {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%,-50%);
    transform: translate(-50%,-50%)
}

.tel-box .tel-btn {
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    z-index: 1
}

.btn-blue-circle {
    position: relative;
    background: #fff;
    color: #1fbccf;
    text-align: center;
    overflow: visible;
    border-radius: .03rem
}

.btn-blue-circle,.btn-blue-circle:after {
    box-sizing: border-box;
    -webkit-box-sizing: border-box
}

.btn-blue-circle:after {
    content: " ";
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    width: 200%;
    height: 200%;
    border: .01rem solid #1fbccf;
    border-radius: .04rem;
    -webkit-border-radius: .04rem;
    transform-origin: 0 0;
    -webkit-transform-origin: 0 0;
    transform: scale(.5);
    -webkit-transform: scale(.5)
}

.btn-blue-circle.disable {
    color: #999;
    position: relative
}

.btn-blue-circle.disable:after {
    content: " ";
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    width: 200%;
    height: 200%;
    border: .01rem solid #999;
    border-radius: .04rem;
    -webkit-border-radius: .04rem;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    transform-origin: 0 0;
    -webkit-transform-origin: 0 0;
    transform: scale(.5);
    -webkit-transform: scale(.5)
}

.btn-blue-circle-android {
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    background: #fff;
    color: #1fbccf;
    text-align: center;
    border: .01rem solid #1fbccf;
    border-radius: .03rem
}

.btn-blue-circle-android.disable {
    color: #999;
    border-color: #999
}

.btn-grayy-circle {
    position: relative;
    background: #fff;
    color: #999;
    text-align: center;
    overflow: visible;
    border-radius: .03rem
}

.btn-grayy-circle,.btn-grayy-circle:after {
    box-sizing: border-box;
    -webkit-box-sizing: border-box
}

.btn-grayy-circle:after {
    content: " ";
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    width: 200%;
    height: 200%;
    border: .01rem solid #999;
    border-radius: .04rem;
    -webkit-border-radius: .04rem;
    transform-origin: 0 0;
    -webkit-transform-origin: 0 0;
    transform: scale(.5);
    -webkit-transform: scale(.5)
}

.btn-grayy-circle.disable {
    color: #999;
    position: relative
}

.btn-grayy-circle.disable:after {
    content: " ";
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    width: 200%;
    height: 200%;
    border: .01rem solid #999;
    border-radius: .04rem;
    -webkit-border-radius: .04rem;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    transform-origin: 0 0;
    -webkit-transform-origin: 0 0;
    transform: scale(.5);
    -webkit-transform: scale(.5)
}

.btn-grayy-circle-android {
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    background: #fff;
    color: #999;
    text-align: center;
    border: .01rem solid #999;
    overflow: visible;
    border-radius: .03rem
}

.btn-grayy-circle-android.disable {
    color: #999;
    border-color: #999
}

.pop-item-list {
    background: #fff
}

.pop-item-list .row {
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 0 .07rem;
    min-height: .44rem
}

.pop-item-list .row .flex-item {
    -ms-flex: 1;
    flex: 1;
    -webkit-box-flex: 1;
    overflow: hidden
}

.pop-item-list .icon-box {
    width: .17rem;
    height: .17rem
}

.pop-item-list .item-name {
    -ms-flex: 1;
    flex: 1;
    -webkit-box-flex: 1;
    line-height: .18rem;
    padding: .1rem
}

.pop-item-list .item-tag {
    width: .15rem;
    height: .15rem;
    padding: 0 .1rem
}

.pop-item-list .item-tag .tag-blue,.pop-item-list .item-tag .tag-pink {
    display: inline-block;
    width: .15rem;
    height: .15rem;
    line-height: .15rem;
    background: #1fbccf;
    color: #fff;
    text-align: center;
    font-size: .12rem
}

.pop-item-list .item-tag .tag-pink {
    background: #f85f80
}

.pop-item-list .item-price {
    width: .5rem;
    text-align: right
}

.pop-item-list .list-title {
    position: relative;
    width: 100%;
    height: .4rem;
    line-height: .4rem;
    padding: 0 .08rem;
    box-sizing: border-box;
    color: #999
}

.pop-item-list .list-title:before {
    content: " ";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: .01rem;
    border-top: .01rem solid #e4e4e4;
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    -webkit-transform: scaleY(.5);
    transform: scaleY(.5)
}

.pop-item-list .list-title:after {
    content: " ";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: .01rem;
    border-bottom: .01rem solid #e4e4e4;
    border-radius: .04rem;
    -webkit-transform-origin: 0 100%;
    transform-origin: 0 100%;
    -webkit-transform: scaleY(.5);
    transform: scaleY(.5)
}

.pop-item-list .check-lable {
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    right: .07rem;
    height: .4rem
}

.pop-item-list .check-lable .mui-switch {
    margin-top: .04rem
}

.x-scroll-list {
    width: 100%;
    overflow-x: auto;
    white-space: nowrap;
    font-size: 0;
    -webkit-overflow-scrolling: touch
}

.tech-list .scroll-item {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    width: .8rem;
    height: 1rem;
    overflow: hidden;
    margin: .1rem .03rem;
    box-sizing: border-box
}

.tech-list .scroll-item:after {
    content: " ";
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    width: 200%;
    height: 200%;
    border: .01rem solid #e4e4e4;
    border-radius: .04rem;
    -webkit-border-radius: .04rem;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    transform-origin: 0 0;
    -webkit-transform-origin: 0 0;
    transform: scale(.5);
    -webkit-transform: scale(.5)
}

.tech-list .tech-pic {
    position: absolute;
    height: .75rem;
    overflow: hidden
}

.tech-list .tech-pic img {
    width: 100%;
    height: auto
}

.tech-list .item-text {
    position: relative;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1;
    height: .25rem;
    line-height: .25rem;
    text-align: center;
    font-size: .14rem
}

.tech-list .item-text:before {
    content: " ";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: .01rem;
    border-top: .01rem solid #e4e4e4;
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    -webkit-transform: scaleY(.5);
    transform: scaleY(.5)
}

.tech-list .current {
    border: .02rem solid #1fbccf;
    border-radius: .01rem
}

.tech-list .current:after {
    display: none
}

.tech-list .current .item-text {
    height: .23rem
}

.tech-list .current .tech-pic {
    left: -.02rem;
    right: -.02rem;
    top: -.02rem
}

.tech-list .disable:before {
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 2;
    background: hsla(0,0%,100%,.5)
}

.picker-box {
    min-height: 4rem
}

.picker-box .loading-box {
    position: absolute;
    top: 1.15rem;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 100;
    background: rgba(0,0,0,.2)
}

.picker-box .loading-box .g-loading {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%,-50%);
    transform: translate(-50%,-50%)
}

.picker-box .loading-box .g-loading-leaf:before {
    background: #111
}

.picker-box .day-picker {
    padding: .08rem .05rem;
    box-sizing: border-box
}

.picker-box .day-picker .item {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    width: .6rem;
    height: .6rem;
    padding-top: .1rem;
    margin: .02rem;
    box-sizing: border-box;
    font-size: .14rem;
    text-align: center
}

.picker-box .day-picker .item:after {
    content: " ";
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    width: 200%;
    height: 200%;
    border: .01rem solid #e4e4e4;
    border-radius: 0;
    -webkit-border-radius: 0;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    transform-origin: 0 0;
    -webkit-transform-origin: 0 0;
    transform: scale(.5);
    -webkit-transform: scale(.5)
}

.picker-box .day-picker .item p {
    line-height: .2rem
}

.picker-box .day-picker .item.current {
    background: #1fbccf;
    color: #fff
}

.picker-box .day-picker .item.current:after {
    display: none
}

.picker-box .apm-tab {
    padding: 0 .07rem;
    margin-bottom: .1rem
}

.picker-box .apm-tab .item {
    display: inline-block;
    width: 50%;
    height: .3rem;
    line-height: .3rem;
    font-size: .14rem;
    text-align: center;
    color: #999
}

.picker-box .apm-tab .item .icon-day,.picker-box .apm-tab .item .icon-night {
    vertical-align: middle;
    margin-right: .03rem
}

.picker-box .apm-tab .item .time {
    display: inline-block;
    vertical-align: middle
}

.picker-box .apm-tab .item.current {
    background: #1fbccf;
    color: #fff
}

.picker-box .apm-tab .item.current .icon-day {
    background-image: url('https://sh-user.51alm.com/asserts/icon-day-white.png')
}

.picker-box .apm-tab .item.current .icon-night {
    background-image: url('https://sh-user.51alm.com/asserts/icon-night-white.png')
}

.picker-box .time-picker {
    min-height: 2rem;
    padding: 0 .05rem .1rem;
    font-size: 0
}

.picker-box .time-picker .item {
    display: inline-block;
    width: 25%;
    height: .54rem;
    padding: .02rem;
    box-sizing: border-box;
    text-align: center;
    font-size: .14rem
}

.picker-box .time-picker .red-star {
    position: absolute;
    right: .07rem;
    top: .05rem;
    color: #f23030;
    font-size: .12rem
}

.picker-box .time-picker .item-box {
    position: relative;
    width: 100%;
    height: 100%;
    border-radius: .02rem
}

.picker-box .time-picker .item-box:after {
    content: " ";
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    width: 200%;
    height: 200%;
    border: .01rem solid #e4e4e4;
    border-radius: .04rem;
    -webkit-border-radius: .04rem;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    transform-origin: 0 0;
    -webkit-transform-origin: 0 0;
    transform: scale(.5);
    -webkit-transform: scale(.5)
}

.picker-box .time-picker .item-box .icon-nextday {
    position: absolute;
    left: 0;
    top: 0
}

.picker-box .time-picker .center-box {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%,-50%);
    transform: translate(-50%,-50%);
    width: 100%
}

.picker-box .time-picker .current .item-box {
    background: #1fbccf;
    color: #fff
}

.picker-box .time-picker .current .item-box .txt-red {
    color: #fff
}

.picker-box .time-picker .disable p {
    color: #999
}

.picker-box .time-picker p {
    line-height: .16rem
}

.picker-box .time-picker .txt-red {
    font-size: .1rem
}

.pay-way-list .row {
    position: relative;
    display: -ms-flexbox;
    display: flex;

    box-sizing: border-box;
    min-height: .44rem;
    padding-left: .07rem
}

.pay-way-list .row:after {
    content: " ";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: .01rem;
    border-bottom: .01rem solid #e4e4e4;
    border-radius: .04rem;
    -webkit-transform-origin: 0 100%;
    transform-origin: 0 100%;
    -webkit-transform: scaleY(.5);
    transform: scaleY(.5)
}

.pay-way-list .pay-info-title {
    display: block;
    text-align: center
}

.pay-way-list .pay-info-title .pay-price {
    padding: .2rem 0;
    font-size: .26rem
}

.pay-way-list .icon-box {
    width: .34rem;
    padding: .13rem 0
}

.pay-way-list .content-box {
    -ms-flex: 1;
    flex: 1;
    -webkit-box-flex: 1;
    line-height: .18rem
}

.pay-way-list .content-box .pay-text {
    padding: .15rem 0
}

.pay-way-list .content-box .rest-info {
    padding: .15rem .07rem .15rem 0
}

.pay-way-list .content-box .rest-info .txt-red {
    float: right;
    padding-right: .2rem
}

.pay-way-list .content-box .icon-radio,.pay-way-list .content-box .icon-radio-checked {
    float: right
}

.pay-way-list .content-box .recharge-box {
    position: relative;
    padding: .15rem .07rem .15rem 0
}

.pay-way-list .content-box .recharge-box:before {
    content: " ";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: .01rem;
    border-top: .01rem solid #e4e4e4;
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    -webkit-transform: scaleY(.5);
    transform: scaleY(.5)
}

.pay-way-list .content-box .recharge-box .txt-red {
    display: -ms-flexbox;
    display: flex;
    display: -webkit-box
}

.pay-way-list .content-box .recharge-box .txt-red .flex-item {
    -ms-flex: 1;
    flex: 1;
    -webkit-box-flex: 1;
    overflow: hidden
}

.pay-way-list .content-box .recharge-box .deposite-rule {
    -ms-flex: 1;
    flex: 1;
    -webkit-box-flex: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap
}

.pay-way-list .content-box .recharge-box .side-info {
    -ms-flex: 1;
    flex: 1;
    -webkit-box-flex: 1
}

.pay-way-list .lo-line {
    display: none;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin: 0 .1rem;
    box-sizing: content-box
}

.pay-way-list .lo-line .lo-add-pay {
    display: block;
    width: .38rem;
    height: .38rem;
    background-size: contain;
    background-repeat: no-repeat;
    background-image: url('https://sh-user.51alm.com/asserts/icon_pay_and.png');
    background-position: 50%
}

.pay-way-list .lo-line .lo-line-whc {
    height: .01rem;
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    background-color: #e4e4e4
}

.pay-way-list .lo-line .lo-line-whc,.separate-row {

    display: -ms-flexbox;
    display: flex
}

.separate-row {
    line-height: .4rem
}

.separate-row .flex-item,.separate-row .left {
    -ms-flex: 1;
    flex: 1;
    -webkit-box-flex: 1;
    overflow: hidden
}

.separate-row .left,.separate-row .right {
    color: #999
}

.page-tab {
    display: -ms-flexbox;
    display: flex;

    position: relative;
    background: #fff;
    overflow: hidden
}

.page-tab .flex-item {
    -ms-flex: 1;
    flex: 1;
    -webkit-box-flex: 1;
    overflow: hidden
}

.page-tab:after {
    content: " ";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: .01rem;
    border-bottom: .01rem solid #e4e4e4;
    border-radius: .04rem;
    -webkit-transform-origin: 0 100%;
    transform-origin: 0 100%;
    -webkit-transform: scaleY(.5);
    transform: scaleY(.5)
}

.page-tab .tab-item {
    -ms-flex: 1;
    flex: 1;
    -webkit-box-flex: 1;
    overflow: hidden;
    line-height: .44rem;
    text-align: center
}

.page-tab .tab-item.current {
    box-shadow: inset 0 -.02rem 0 0 #1fbccf;
    color: #1fbccf
}

.pay-bar {
    display: -ms-flexbox;
    display: flex;

    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    height: .68rem;
    background: #fff
}

.pay-bar .flex-item,.pay-bar .price-info {
    -ms-flex: 1;
    flex: 1;
    -webkit-box-flex: 1;
    overflow: hidden
}

.pay-bar .price-info {
    padding: 0 .08rem
}

.pay-bar .price-info .txt-red {
    display: inline;
    font-size: .12rem
}

.pay-bar .price-info strong {
    vertical-align: -.02rem;
    padding-left: .05rem;
    font-size: .2rem
}

.pay-bar .price-info .discount-info {
    display: inline;
    font-size: .12rem
}

.pay-bar .btn-red {
    width: 1.5rem!important;
    border-radius: .03rem;
    margin-right: .07rem
}

.number-counter {
    position: relative;
    width: 1.02rem;
    height: .3rem;
    box-sizing: border-box;
    overflow: hidden
}

.number-counter:after {
    content: " ";
    position: absolute;
    top: 0;
    left: 0;
    width: 200%;
    height: 200%;
    border: .01rem solid #e4e4e4;
    border-radius: .04rem;
    -webkit-border-radius: .04rem;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    transform-origin: 0 0;
    -webkit-transform-origin: 0 0;
    transform: scale(.5);
    -webkit-transform: scale(.5);
    z-index: 1
}

.number-counter .left,.number-counter .right {
    display: inline-block;
    position: relative;
    width: .3rem;
    height: .3rem;
    line-height: .28rem;
    text-align: center;
    font-size: .2rem;
    z-index: 10
}

.number-counter .left.disable,.number-counter .right.disable {
    color: #999
}

.number-counter .right {
    float: right
}

.number-counter .number-box {
    position: relative;
    position: absolute;
    left: .3rem;
    right: .3rem;
    top: 0;
    bottom: 0;
    line-height: .3rem;
    text-align: center;
    font-size: .17rem
}

.number-counter .number-box:before {
    content: " ";
    position: absolute;
    left: 0;
    top: 0;
    width: .01rem;
    height: 100%;
    border-left: .01rem solid #e4e4e4;
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    -webkit-transform: scaleX(.5);
    transform: scaleX(.5)
}

.number-counter .number-box:after {
    content: " ";
    position: absolute;
    top: 0;
    right: 0;
    width: .01rem;
    height: 100%;
    border-right: .01rem solid #e4e4e4;
    -webkit-transform-origin: 100% 0;
    transform-origin: 100% 0;
    -webkit-transform: scaleX(.5);
    transform: scaleX(.5)
}

.number-counter-android {
    position: relative;
    border: .01rem solid #e4e4e4;
    border-radius: .02rem;
    width: 1.02rem;
    height: .3rem;
    box-sizing: border-box;
    overflow: hidden
}

.number-counter-android .left,.number-counter-android .right {
    display: inline-block;
    position: relative;
    width: .3rem;
    height: .3rem;
    line-height: .26rem;
    text-align: center;
    font-size: .2rem;
    z-index: 10
}

.number-counter-android .left.disable,.number-counter-android .right.disable {
    color: #999
}

.number-counter-android .right {
    float: right
}

.number-counter-android .number-box {
    position: absolute;
    left: .3rem;
    right: .3rem;
    top: 0;
    bottom: 0;
    border-right: .01rem solid #e4e4e4;
    border-left: .01rem solid #e4e4e4;
    box-sizing: border-box;
    line-height: .28rem;
    text-align: center;
    font-size: .17rem
}

.result-list {
    position: relative;
    background: #fff
}

.result-list:before {
    content: " ";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: .01rem;
    border-top: .01rem solid #e4e4e4;
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    -webkit-transform: scaleY(.5);
    transform: scaleY(.5)
}

.result-list .result-list-item {
    position: relative;
    padding: .15rem .07rem;
    box-sizing: border-box;
    line-height: .18rem;
    font-size: .14rem
}

.result-list .result-list-item:after {
    content: " ";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: .01rem;
    border-bottom: .01rem solid #e4e4e4;
    border-radius: .04rem;
    -webkit-transform-origin: 0 100%;
    transform-origin: 0 100%;
    -webkit-transform: scaleY(.5);
    transform: scaleY(.5)
}

@media screen and (-webkit-device-pixel-ratio: 3) and (device-width:375px) and (device-height:812px) {
    .btn-order-fix,.pay-bar {
        padding-bottom:.27rem
    }

    .page-footer .page-footer-btn-box {
        padding-bottom: .27rem!important
    }

    .pop-drawer .pop-footer .pop-btn-footer-box {
        padding-bottom: .27rem
    }
}

.book-wrap {
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -ms-flex-direction: column;
    flex-direction: column;
    height: 100%;
    overflow: hidden
}

.book-wrap .flex-item {
    -ms-flex: 1;
    flex: 1;
    -webkit-box-flex: 1;
    overflow: hidden
}

.book-wrap .page-footer {
    position: relative;
    z-index: 20
}

.book-wrap .book-content-wrap {
    -ms-flex: 1;
    flex: 1;
    -webkit-box-flex: 1;
    overflow: hidden;
    overflow: scroll;
    position: relative;
    -webkit-overflow-scrolling: touch
}

.book-wrap .book-section {
    margin-bottom: .1rem
}

.book-wrap .book-section .time-box {
    height: .6rem
}

.book-wrap .book-section .time-box .title {
    line-height: .6rem;
    color: #1fbccf
}

.book-wrap .book-section .time-box .content {
    text-align: right
}

.book-list .row {
    display: -ms-flexbox;
    display: flex;

    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 0 .08rem;
    min-height: .5rem;
    background: #fff
}

.book-list .row .title {
    height: 100%;
    font-size: .14rem;
    color: #999
}

.book-list .row .icon-arrow-right {
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    right: .08rem
}

.book-list .row.hasarrow {
    padding-right: .24rem
}

.book-list .content {
    -ms-flex: 1;
    flex: 1;
    -webkit-box-flex: 1;
    padding-left: .05rem
}

.book-list .content .number-counter,.book-list .content .number-counter-android {
    float: right
}

.book-list .content input {
    width: 100%
}

.book-list .remark .title {
    height: .5rem;
    padding-top: .1rem;
    box-sizing: border-box;
    -webkit-box-sizing: border-box
}

.book-list .remark textarea {
    vertical-align: top;
    width: 100%;
    height: .58rem;
    padding: .1rem 0;
    box-sizing: border-box;
    -webkit-box-sizing: border-box
}

.book-list .name-wrap {
    display: -ms-flexbox;
    display: flex;
    display: -webkit-box
}

.book-list .name-wrap .flex-item,.book-list .name-wrap .name-box {
    -ms-flex: 1;
    flex: 1;
    -webkit-box-flex: 1;
    overflow: hidden
}

.book-list .name-wrap .name-box {
    position: relative;
    padding-right: .1rem
}

.book-list .name-wrap .name-box input {
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%)
}

.book-list .gender-selector {
    display: -ms-flexbox;
    display: flex;

    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    width: .85rem;
    height: .5rem;
    font-size: 0
}

.book-list .gender-selector .flex-item {
    -ms-flex: 1;
    flex: 1;
    -webkit-box-flex: 1;
    overflow: hidden
}

.book-list .gender-selector .btn-blue-circle {
    display: inline-block;
    width: .4rem;
    height: .25rem;
    line-height: .25rem;
    font-size: .13rem
}

.book-list .gender-selector .btn-blue-circle:last-child {
    margin-left: .05rem
}

.book-list .radio-item {
    line-height: .5rem
}

.book-list .radio-item .radio-txt {
    padding-left: .05rem
}

.book-list .discount-info {
    text-align: right
}

.book-item-list .row {
    display: -ms-flexbox;
    display: flex;

    padding: 0 .08rem;
    background: #fff
}

.book-item-list .icon-box {
    width: .25rem
}

.book-item-list .icon-box [class*=icon-] {
    margin-top: .17rem
}

.book-item-list .middle-box {
    -ms-flex: 1;
    flex: 1;
    -webkit-box-flex: 1;
    padding: 0 .1rem .2rem 0
}

.book-item-list .middle-box .top {
    width: 100%;
    min-height: .14rem;
    overflow: hidden
}

.book-item-list .middle-box .top .txt-blue {
    -webkit-box-orient: vertical;

    -webkit-line-clamp: 1;
    overflow: hidden;
    padding: .17rem 0;
    height: .14rem
}

.book-item-list .middle-box .bottom {
    min-height: .14rem
}

.book-item-list .middle-box .bottom .item-row {
    display: -ms-flexbox;
    display: flex;

    margin-bottom: .19rem
}

.book-item-list .middle-box .bottom .item-row:last-child {
    margin-bottom: 0
}

.book-item-list .middle-box .bottom .item-name {
    -ms-flex: 1;
    flex: 1;
    -webkit-box-flex: 1;
    padding-right: .05rem;
    line-height: .16rem
}

.book-item-list .middle-box .bottom .price-box {
    display: inline-block;
    line-height: .16rem
}

.book-item-list .middle-box .icon-edit {
    vertical-align: top;
    margin-left: .03rem
}

.book-item-list .side-box {
    position: absolute;
    right: .08rem;
    top: 0;
    z-index: 10;
    padding: .1rem 0 0
}

.book-item-list .side-box [class*=btn-] {
    height: .3rem;
    line-height: .3rem;
    padding: 0 .07rem;
    border-radius: .02rem
}

.radio-list {
    font-size: 0
}

.radio-list .radio-item {
    display: inline-block;
    margin-right: .3rem;
    font-size: .14rem
}

.radio-list .radio-item:last-child {
    margin-right: 0
}

.page-footer {
    bottom: 0;
    left: 0;
    right: 0
}

.page-footer .order-more {
    width: .8rem;
    height: .3rem;
    line-height: .3rem
}

.order-list-wrap {
    display: -ms-flexbox;
    display: flex;

    -webkit-box-orient: vertical;
    -ms-flex-direction: column;
    flex-direction: column;
    height: 100%;
    font-size: .16rem;
    overflow: hidden
}

.order-list-wrap .flex-item {
    -ms-flex: 1;
    flex: 1;
    -webkit-box-flex: 1;
    overflow: hidden
}

.order-list-wrap .list-holder {
    padding-top: .2rem;
    text-align: center;
    font-size: .16rem
}

.order-list-wrap .order-list {
    -ms-flex: 1;
    flex: 1;
    -webkit-box-flex: 1;
    overflow: hidden;
    padding: .07rem;
    background: transparent;
    overflow: scroll;
    -webkit-overflow-scrolling: touch
}

.order-list-wrap .order-list .order-item {
    background: #fff;
    border-radius: .03rem;
    margin-bottom: .1rem
}

.order-list-wrap .order-list .order-item .header {
    display: -ms-flexbox;
    display: flex;

    position: relative;
    height: .44rem;
    padding: 0 .07rem;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center
}

.order-list-wrap .order-list .order-item .header:after {
    content: " ";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: .01rem;
    border-bottom: .01rem solid #e4e4e4;
    border-radius: .04rem;
    -webkit-transform-origin: 0 100%;
    transform-origin: 0 100%;
    -webkit-transform: scaleY(.5);
    transform: scaleY(.5)
}

.order-list-wrap .order-list .order-item .header .ico-box {
    width: .2rem;
    position: relative
}

.order-list-wrap .order-list .order-item .header .icon-shop {
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%)
}

.order-list-wrap .order-list .order-item .header .shop-name {
    -ms-flex: 1;
    flex: 1;
    -webkit-box-flex: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    line-height: .44rem
}

.order-list-wrap .order-list .order-item .header .order-status {
    padding-left: .07rem;
    line-height: .44rem
}

.order-list-wrap .order-list .order-item .body {
    font-size: .14rem;
    position: relative;
    padding: .06rem .07rem
}

.order-list-wrap .order-list .order-item .body:after {
    content: " ";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: .01rem;
    border-bottom: .01rem solid #e4e4e4;
    border-radius: .04rem;
    -webkit-transform-origin: 0 100%;
    transform-origin: 0 100%;
    -webkit-transform: scaleY(.5);
    transform: scaleY(.5)
}

.order-list-wrap .order-list .order-item .body .item {
    display: -ms-flexbox;
    display: flex;

    padding: .04rem 0;
    line-height: .26rem;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center
}

.order-list-wrap .order-list .order-item .body .item .ico-box {
    position: relative;
    width: .2rem
}

.order-list-wrap .order-list .order-item .body .item .ico-box [class*=icon-] {
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%)
}

.order-list-wrap .order-list .order-item .body .item .content-box {
    -ms-flex: 1;
    flex: 1;
    -webkit-box-flex: 1;
    overflow: hidden
}

.order-list-wrap .order-list .order-item .body .order-detail-list {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center
}

.order-list-wrap .order-list .order-item .body .order-detail-list .detail-row {
    display: -ms-flexbox;
    display: flex;
    display: -webkit-box
}

.order-list-wrap .order-list .order-item .body .order-detail-list .detail-row .item-info {
    -ms-flex: 1;
    flex: 1;
    -webkit-box-flex: 1;
    overflow: hidden;
    padding: .04rem 0;
    line-height: .18rem
}

.order-list-wrap .order-list .order-item .body .order-detail-list .detail-row .item-side-info {
    width: .8rem;
    padding: .04rem 0;
    line-height: .18rem
}

.order-list-wrap .order-list .order-item .footer {
    display: -ms-flexbox;
    display: flex;

    height: .48rem;
    line-height: .48rem;
    padding: 0 .07rem;
    font-size: .15rem;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center
}

.order-list-wrap .order-list .order-item .footer .ico-box {
    position: relative;
    width: .2rem
}

.order-list-wrap .order-list .order-item .footer .ico-box [class*=icon-] {
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%)
}

.order-list-wrap .order-list .order-item .footer .price-info {
    -ms-flex: 1;
    flex: 1;
    -webkit-box-flex: 1;
    overflow: hidden
}

.order-list-wrap .order-list .order-item .footer .btn-box {
    position: relative;
    width: .8rem;
    padding: 0 .07rem
}

.order-list-wrap .order-list .order-item .footer .btn-box [class*=btn-] {
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    width: .8rem;
    height: .3rem;
    line-height: .3rem
}

.order-detail-wrap {
    display: -ms-flexbox;
    display: flex;

    -webkit-box-orient: vertical;
    -ms-flex-direction: column;
    flex-direction: column;
    height: 100%;
    box-sizing: border-box;
    overflow: hidden
}

.order-detail-wrap .flex-item {
    -ms-flex: 1;
    flex: 1;
    -webkit-box-flex: 1;
    overflow: hidden
}

.order-detail-wrap .page-body {
    -ms-flex: 1;
    flex: 1;
    -webkit-box-flex: 1;
    overflow: hidden;
    -webkit-overflow-scrolling: touch;
    overflow: auto
}

.order-detail-wrap .page-footer {
    position: static
}

.no-data {
    background: #fff;
    height: 100%
}

.order-process-bar {
    position: relative;
    height: .7rem
}

.order-process-bar .process-blocks {
    position: relative;
    height: 100%;
    z-index: 2;
    font-size: 0
}

.order-process-bar .process-blocks .item {
    position: relative;
    display: inline-block;
    width: 20%;
    height: 100%;
    text-align: center
}

.order-process-bar .process-blocks .item .process-name {
    width: 100%;
    height: .26rem;
    margin-top: .2rem;
    font-size: .12rem;
    color: #999
}

.order-process-bar .process-blocks .item .process-dot {
    position: absolute;
    left: 50%;
    -webkit-transform: translate(-50%);
    transform: translate(-50%);
    bottom: .1rem;
    width: .12rem;
    height: .12rem;
    z-index: 1;
    background-image: url('https://sh-user.51alm.com/asserts/icon-uncheck.png');
    background-repeat: no-repeat;
    background-size: contain
}

.order-process-bar.process1 .item:first-child {
    background-image: url('https://sh-user.51alm.com/asserts/bg-current-process.png');
    background-repeat: no-repeat;
    background-size: .6rem auto;
    background-position: 50% .16rem
}

.order-process-bar.process1 .item:first-child .process-name {
    color: #fff
}

.order-process-bar.process1 .item:first-child .process-dot {
    background-image: url('https://sh-user.51alm.com/asserts/icon-radiobox-checked.png')
}

.order-process-bar.process2 .item:first-child .process-dot,.order-process-bar.process2 .item:nth-child(2) .process-dot {
    background-image: url('https://sh-user.51alm.com/asserts/icon-radiobox-checked.png')
}

.order-process-bar.process2 .item:first-child .process-name,.order-process-bar.process2 .item:nth-child(2) .process-name {
    color: #111
}

.order-process-bar.process2 .item:nth-child(2) {
    background-image: url('https://sh-user.51alm.com/asserts/bg-current-process.png');
    background-repeat: no-repeat;
    background-size: .6rem auto;
    background-position: 50% .16rem
}

.order-process-bar.process2 .item:nth-child(2) .process-name {
    color: #fff
}

.order-process-bar.process3 .item:first-child .process-dot,.order-process-bar.process3 .item:nth-child(2) .process-dot,.order-process-bar.process3 .item:nth-child(3) .process-dot {
    background-image: url('https://sh-user.51alm.com/asserts/icon-radiobox-checked.png')
}

.order-process-bar.process3 .item:first-child .process-name,.order-process-bar.process3 .item:nth-child(2) .process-name,.order-process-bar.process3 .item:nth-child(3) .process-name {
    color: #111
}

.order-process-bar.process3 .item:nth-child(3) {
    background-image: url('https://sh-user.51alm.com/asserts/bg-current-process.png');
    background-repeat: no-repeat;
    background-size: .6rem auto;
    background-position: 50% .16rem
}

.order-process-bar.process3 .item:nth-child(3) .process-name {
    color: #fff
}

.order-process-bar.process4 .item:first-child .process-dot,.order-process-bar.process4 .item:nth-child(2) .process-dot,.order-process-bar.process4 .item:nth-child(3) .process-dot,.order-process-bar.process4 .item:nth-child(4) .process-dot {
    background-image: url('https://sh-user.51alm.com/asserts/icon-radiobox-checked.png')
}

.order-process-bar.process4 .item:first-child .process-name,.order-process-bar.process4 .item:nth-child(2) .process-name,.order-process-bar.process4 .item:nth-child(3) .process-name,.order-process-bar.process4 .item:nth-child(4) .process-name {
    color: #111
}

.order-process-bar.process4 .item:nth-child(4) {
    background-image: url('https://sh-user.51alm.com/asserts/bg-current-process.png');
    background-repeat: no-repeat;
    background-size: .6rem auto;
    background-position: 50% .16rem
}

.order-process-bar.process4 .item:nth-child(4) .process-name {
    color: #fff
}

.order-process-bar.process5 .item:first-child .process-dot,.order-process-bar.process5 .item:nth-child(2) .process-dot,.order-process-bar.process5 .item:nth-child(3) .process-dot,.order-process-bar.process5 .item:nth-child(4) .process-dot,.order-process-bar.process5 .item:nth-child(5) .process-dot {
    background-image: url('https://sh-user.51alm.com/asserts/icon-radiobox-checked.png')
}

.order-process-bar.process5 .item:first-child .process-name,.order-process-bar.process5 .item:nth-child(2) .process-name,.order-process-bar.process5 .item:nth-child(3) .process-name,.order-process-bar.process5 .item:nth-child(4) .process-name,.order-process-bar.process5 .item:nth-child(5) .process-name {
    color: #111
}

.order-process-bar.process5 .item:nth-child(5) {
    background-image: url('https://sh-user.51alm.com/asserts/bg-current-process.png');
    background-repeat: no-repeat;
    background-size: .6rem auto;
    background-position: 50% .16rem
}

.order-process-bar.process5 .item:nth-child(5) .process-name {
    color: #fff
}

.order-process-bar .process-bar {
    position: absolute;
    left: 10%;
    right: 10%;
    bottom: .15rem;
    z-index: 1;
    height: .02rem;
    border-radius: .01rem;
    background: #ccc
}

.page-footer {
    position: relative;
    position: fixed;
    background: #fff;
    height: .68rem
}

.page-footer:before {
    content: " ";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: .01rem;
    border-top: .01rem solid #e4e4e4;
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    -webkit-transform: scaleY(.5);
    transform: scaleY(.5)
}

.page-footer .page-footer-btn-box {
    position: relative;
    padding: .1rem
}

.page-footer .page-footer-btn-box:before {
    content: " ";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: .01rem;
    border-top: .01rem solid #e4e4e4;
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    -webkit-transform: scaleY(.5);
    transform: scaleY(.5)
}

.page-footer .btn-red {
    width: 100%
}

.page-footer .btn-red,.page-footer .btnc-red {
    height: .48rem;
    line-height: .48rem;
    border-radius: .03rem
}

.page-footer .btnc-red {
    position: relative;
    width: 40%;
    float: right;
    box-sizing: border-box;
    background: #f23030;
    color: #fff;
    text-align: center;
    overflow: visible
}

.page-footer .btnc-red:after {
    content: " ";
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    width: 200%;
    height: 200%;
    border: .01rem solid #f23030;
    border-radius: .04rem;
    -webkit-border-radius: .04rem;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    transform-origin: 0 0;
    -webkit-transform-origin: 0 0;
    transform: scale(.5);
    -webkit-transform: scale(.5)
}

.page-footer .btn-cancel {
    position: relative;
    width: 40%;
    height: .48rem;
    float: left;
    box-sizing: border-box;
    line-height: .48rem;
    border-radius: .03rem;
    background: #fff;
    color: #999;
    text-align: center;
    overflow: visible
}

.page-footer .btn-cancel:after {
    content: " ";
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    width: 200%;
    height: 200%;
    border: .01rem solid #999;
    border-radius: .04rem;
    -webkit-border-radius: .04rem;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    transform-origin: 0 0;
    -webkit-transform-origin: 0 0;
    transform: scale(.5);
    -webkit-transform: scale(.5)
}

.order-detail-section {
    margin-bottom: .1rem
}

.order-status-bar {
    line-height: .25rem;
    padding: 0 .07rem;
    font-size: .14rem;
    color: #fff
}

.order-status-bar.red {
    background: #f23030
}

.order-status-bar.yellow {
    background: #f07628
}

.order-status-bar.gray {
    background: #ccc
}

.order-status-bar .status-tip {
    float: right
}

.order-countdown {
    background: #fff;
    text-align: center
}

.order-countdown .title {
    padding: .2rem 0
}

.order-countdown .time-box {
    padding-bottom: .2rem;
    font-size: 0
}

.order-countdown .time-item {
    width: .3rem;
    height: .3rem;
    line-height: .3rem;
    border-radius: .02rem;
    background: #1fbccf;
    text-align: center;
    color: #fff
}

.order-countdown .devide,.order-countdown .time-item {
    display: inline-block;
    margin: 0 .03rem;
    font-size: .14rem
}

.order-countdown .devide {
    color: #999
}

.order-list {
    background: #fff
}

.order-list .title {
    height: .4rem;
    line-height: .4rem;
    color: #999;
    font-size: .14rem
}

.order-list .row {
    min-height: .4rem;
    padding: 0 .07rem
}

.order-list .shop-detail-info {
    display: -ms-flexbox;
    display: flex;
    display: -webkit-box
}

.order-list .shop-detail-info .left {
    -ms-flex: 1;
    flex: 1;
    -webkit-box-flex: 1;
    padding: .15rem 0
}

.order-list .shop-detail-info .right {
    position: relative;
    width: .6rem;
    text-align: center
}

.order-list .shop-detail-info .right .border {
    position: relative;
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    width: 0;
    height: .4rem
}

.order-list .shop-detail-info .right .border:after {
    content: " ";
    position: absolute;
    top: 0;
    right: 0;
    width: .01rem;
    height: 100%;
    border-right: .01rem solid #e4e4e4;
    -webkit-transform-origin: 100% 0;
    transform-origin: 100% 0;
    -webkit-transform: scaleX(.5);
    transform: scaleX(.5)
}

.order-list .shop-detail-info .right .icon-phone {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%,-50%);
    transform: translate(-50%,-50%)
}

.order-list .shop-detail-info .right .tel-btn {
    position: absolute;
    left: 0;
    top: 0;
    display: inline-block;
    width: 100%;
    height: 100%
}

.order-list .shop-detail-info .shop-name {
    margin-bottom: .1rem;
    font-size: .14rem;
    font-weight: 700
}

.order-list .shop-detail-info .icon-position {
    width: .13rem;
    height: .13rem;
    vertical-align: -.01rem
}

.order-list .shop-detail-info .address-txt {
    color: #1fbccf
}

.order-list .txt-blue {
    line-height: .4rem
}

.order-list .icon-arrow-right {
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    right: .07rem
}

.order-list .order-item-detail {
    display: -ms-flexbox;
    display: flex;
    display: -webkit-box
}

.order-list .order-item-detail .flex-item {
    -ms-flex: 1;
    flex: 1;
    -webkit-box-flex: 1;
    overflow: hidden
}

.order-list .order-item-detail .icon-box {
    position: relative;
    width: .2rem;
    height: .4rem
}

.order-list .order-item-detail .icon-box .icon-people {
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%)
}

.order-list .order-item-detail .content-box {
    -ms-flex: 1;
    flex: 1;
    -webkit-box-flex: 1;
    overflow: hidden
}

.order-list .order-item-detail .content-box .bottom {
    padding-bottom: .14rem
}

.order-list .order-item-detail .content-box .txt-gray {
    padding-left: .05rem
}

.order-list .order-single-item {
    display: -ms-flexbox;
    display: flex;

    line-height: .18rem
}

.order-list .order-single-item .flex-item {
    -ms-flex: 1;
    flex: 1;
    -webkit-box-flex: 1;
    overflow: hidden
}

.order-list .order-single-item .item-price {
    width: .5rem;
    padding-right: .38rem;
    color: #f23030;
    text-align: right
}

.order-list .order-single-item .item-name {
    -ms-flex: 1;
    flex: 1;
    -webkit-box-flex: 1;
    overflow: hidden;
    padding-right: .05rem
}

.order-brief-info {
    padding: .05rem;
    background: #fff
}

.order-brief-info .brief-item {
    padding: .05rem;
    color: #999;
    font-size: .12rem
}

.book-result-wrap {
    height: 100%;
    background: #fff
}

.book-result-wrap .section {
    border-bottom: .1rem solid #ebebeb;
    background: #fff
}

.book-result-wrap .section .pay-info {
    position: relative;
    height: .78rem;
    line-height: .78rem;
    text-align: center;
    font-size: .18rem;
    font-weight: 700
}

.book-result-wrap .section .pay-info:after {
    content: " ";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: .01rem;
    border-bottom: .01rem solid #e4e4e4;
    border-radius: .04rem;
    -webkit-transform-origin: 0 100%;
    transform-origin: 0 100%;
    -webkit-transform: scaleY(.5);
    transform: scaleY(.5)
}

.book-result-wrap .status-title {
    position: relative;
    height: 1rem;
    line-height: 1rem;
    background: #1fbccf;
    color: #fff;
    font-size: .2rem;
    font-weight: 700;
    text-align: center
}

.book-result-wrap .status-title .icon-success {
    width: .4rem;
    height: .4rem;
    margin-right: .05rem;
    vertical-align: -.12rem
}

.book-result-wrap .book-result-list {
    padding: .2rem .07rem;
    color: #999
}

.book-result-wrap .book-result-list .row {
    display: -ms-flexbox;
    display: flex;

    padding: .02rem 0
}

.book-result-wrap .book-result-list .row-title {
    width: .6rem;
    line-height: .2rem;
    color: #999
}

.book-result-wrap .book-result-list .row-content {
    -ms-flex: 1;
    flex: 1;
    -webkit-box-flex: 1;
    line-height: .2rem;
    padding-left: .1rem
}

.book-result-wrap .pay-box {
    margin: .12rem -.07rem 0;
    overflow: hidden
}

.book-result-wrap .pay-box .pay-detail-list {
    position: relative;
    font-size: .12rem
}

.book-result-wrap .pay-box .pay-detail-list:before {
    content: " ";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: .01rem;
    border-top: .01rem solid #e4e4e4;
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    -webkit-transform: scaleY(.5);
    transform: scaleY(.5)
}

.book-result-wrap .pay-box .pay-detail-list .separate-row {
    position: relative;
    padding: 0 .07rem
}

.book-result-wrap .pay-box .pay-detail-list .separate-row:after {
    content: " ";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: .01rem;
    border-bottom: .01rem solid #e4e4e4;
    border-radius: .04rem;
    -webkit-transform-origin: 0 100%;
    transform-origin: 0 100%;
    -webkit-transform: scaleY(.5);
    transform: scaleY(.5)
}

.book-result-wrap .pay-box .pay-detail-list .separate-row .right .txt-red {
    padding-left: .05rem
}

.book-result-wrap .pay-box .txt-red {
    float: right;
    padding: .07rem;
    font-size: .12rem
}

.book-result-wrap .btn-box {
    padding: 0 0 .2rem;
    text-align: center
}

.book-result-wrap .btn-blue-circle {
    display: inline-block;
    width: 2.5rem;
    height: .4rem;
    line-height: .4rem;
    border-radius: .02rem
}

.book-result-wrap .tips-section {
    padding: .2rem .07rem;
    line-height: .16rem;
    font-size: .12rem;
    color: #999;
    background: #fff
}

.book-result-wrap .tips-section .row {
    line-height: .2rem
}

.recharge-bg-box {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    box-sizing: border-box;
    background: #fff
}

.recharge-wrap {
    padding: .15rem .07rem;
    background: #fff
}

.recharge-header {
    width: 100%;
    height: 1rem;
    border-radius: .04rem;
    background: #1fbccf;
    color: #fff;
    box-shadow: 0 .03rem .09rem 0 rgba(31,188,207,.7)
}

.recharge-header .title {
    padding: .1rem;
    font-size: .14rem
}

.recharge-header .title .icon-recharge {
    display: inline-block;
    vertical-align: -.03rem;
    width: .22rem;
    height: .2rem;
    margin-right: .07rem
}

.recharge-header .rest {
    text-align: center;
    font-size: .3rem
}

.recharge-header .rest span {
    font-size: .17rem
}

.recharge-body {
    padding-top: .26rem
}

.recharge-body .title {
    margin-bottom: .08rem;
    color: #1fbccf;
    font-size: .14rem
}

.recharge-body .recharge-list {
    font-size: 0
}

.recharge-body .recharge-list .recharge-item-wrap {
    display: inline-block;
    width: 50%;
    padding: .1rem .13rem;
    box-sizing: border-box;
    text-align: center
}

.recharge-body .recharge-list .recharge-item-wrap.current .recharge-item {
    position: relative
}

.recharge-body .recharge-list .recharge-item-wrap.current .recharge-item:after {
    content: "";
    position: absolute;
    right: 0;
    top: 0;
    display: block;
    width: .31rem;
    height: .31rem;
    background-image: url('https://sh-user.51alm.com/asserts/icon-current-corner.png');
    background-repeat: no-repeat;
    background-size: contain
}

.recharge-body .recharge-list .recharge-item-wrap.current .border-box {
    position: relative
}

.recharge-body .recharge-list .recharge-item-wrap.current .border-box:after {
    content: " ";
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    width: 200%;
    height: 200%;
    border: .01rem solid #1fbccf;
    border-radius: .12rem;
    -webkit-border-radius: .12rem;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    transform-origin: 0 0;
    -webkit-transform-origin: 0 0;
    transform: scale(.5);
    -webkit-transform: scale(.5)
}

.recharge-body .recharge-list .recharge-item-wrap.current .bonus,.recharge-body .recharge-list .recharge-item-wrap.current .price {
    color: #1fbccf
}

.recharge-body .recharge-list .recharge-item {
    width: 100%;
    height: 1rem
}

.recharge-body .recharge-list .border-box {
    position: relative;
    width: 100%;
    height: 100%;
    box-sizing: border-box
}

.recharge-body .recharge-list .border-box:after {
    content: " ";
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    width: 200%;
    height: 200%;
    border: .01rem solid #e4e4e4;
    border-radius: .04rem;
    -webkit-border-radius: .04rem;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    transform-origin: 0 0;
    -webkit-transform-origin: 0 0;
    transform: scale(.5);
    -webkit-transform: scale(.5)
}

.recharge-body .recharge-list .center-box {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%,-50%);
    transform: translate(-50%,-50%)
}

.recharge-body .recharge-list .price {
    margin-bottom: .05rem;
    font-size: .17rem
}

.recharge-body .recharge-list .price strong {
    font-size: .22rem
}

.recharge-body .recharge-list .bonus {
    font-size: .14rem;
    color: #999
}

.recharge-body .btn-box {
    padding: .2rem .13rem 0;
    text-align: center
}

.recharge-body .btn-box .btn-blue {
    width: 100%;
    height: .44rem;
    line-height: .44rem;
    font-size: .14rem
}

.recharge-body .btn-box .recharge-tip {
    padding: .1rem 0;
    color: #999;
    font-size: .12rem
}

.recharge-confirm-box .detail {
    text-align: center
}

.recharge-confirm-box .detail .item {
    display: inline-block;
    padding: 0 .1rem
}

.recharge-confirm-box .detail .top {
    padding-bottom: .1rem;
    font-size: .12rem;
    color: #999
}

.recharge-confirm-box .confirm-txt {
    padding: .2rem 0 0;
    text-align: center;
    font-size: .12rem;
    color: #999
}

.recharge-confirm-box .confirm-txt strong {
    font-size: .17rem
}

.coupon-list-wrap {
    display: -ms-flexbox;
    display: flex;

    -webkit-box-orient: vertical;
    -ms-flex-direction: column;
    flex-direction: column
}

.coupon-list-wrap .flex-item {
    -ms-flex: 1;
    flex: 1;
    -webkit-box-flex: 1;
    overflow: hidden
}

.page-header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 100
}

.coupon-search {
    position: relative;
    height: .4rem;
    width: 100%;
    padding: .1rem 0;
    border-radius: .02rem;
    overflow: hidden
}

.coupon-search .coupon-input {
    width: 100%;
    height: .4rem;
    border-radius: .02rem 0 0 .02rem;
    padding: .1rem .6rem .1rem .1rem;
    box-sizing: border-box
}

.coupon-search .btn-blue {
    position: absolute;
    right: 0;
    width: .6rem;
    line-height: .4rem;
    border-radius: 0 .02rem .02rem 0
}

.coupon-list {
    padding: .4rem .07rem
}

.coupon-list .item {
    position: relative;
    height: 0;
    padding-bottom: 31.7%;
    margin-bottom: .05rem;
    background-repeat: no-repeat;
    background-size: 100% auto;
    background-position: 0 -.05rem
}

.coupon-list .item.current .icon-selected-coupon {
    display: block!important
}

.coupon-list .used:after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: 10;
    background: hsla(0,0%,100%,.45)
}

.coupon-list .item-wrap {
    display: -ms-flexbox;
    display: flex;

    -webkit-box-orient: vertical;
    -ms-flex-direction: column;
    flex-direction: column;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    padding-left: 17%
}

.coupon-list .item-wrap .flex-item {
    -ms-flex: 1;
    flex: 1;
    -webkit-box-flex: 1;
    overflow: hidden
}

.coupon-list .item-wrap .icon-selected-coupon {
    display: none;
    position: absolute;
    right: 0;
    bottom: .03rem
}

.coupon-list .item-wrap .top {
    -ms-flex: 1;
    flex: 1;
    -webkit-box-flex: 1;
    position: relative;
    margin-left: .1rem;
    padding-right: 1rem;
    box-sizing: border-box;
    overflow: hidden
}

.coupon-list .item-wrap .top:after {
    content: " ";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: .01rem;
    border-bottom: .01rem solid #e4e4e4;
    border-radius: .04rem;
    -webkit-transform-origin: 0 100%;
    transform-origin: 0 100%;
    -webkit-transform: scaleY(.5);
    transform: scaleY(.5)
}

.coupon-list .item-wrap .top .coupon-title {
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    left: 0;
    right: 1rem
}

.coupon-list .item-wrap .top .coupon-title .coupon-name {
    margin-bottom: .12rem;
    font-size: .16rem
}

.coupon-list .item-wrap .top .coupon-title .coupon-description {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: .12rem;
    line-height: .16rem;
    color: #999
}

.coupon-list .item-wrap .top .coupon-detail {
    position: absolute;
    right: 0;
    top: .08rem;
    width: 1rem;
    padding-right: .07rem;
    box-sizing: border-box;
    font-size: .28rem;
    text-align: right
}

.coupon-list .item-wrap .top .coupon-detail .small {
    font-size: .16rem
}

.coupon-list .item-wrap .top .coupon-name {
    font-size: .2rem
}

.coupon-list .item-wrap .bottom {
    display: -ms-flexbox;
    display: flex;

    height: .4rem;
    line-height: .4rem;
    margin-left: .1rem
}

.coupon-list .item-wrap .bottom .exp-box,.coupon-list .item-wrap .bottom .flex-item {
    -ms-flex: 1;
    flex: 1;
    -webkit-box-flex: 1;
    overflow: hidden
}

.coupon-list .item-wrap .bottom .exp-box {
    color: #999
}

.coupon-list .item-wrap .bottom .use {
    width: 1rem;
    text-align: right;
    padding-right: .15rem;
    box-sizing: border-box
}

.coupon-list .coupon-electronic {
    background-image: url('https://sh-user.51alm.com/asserts/bg-coupon-electronic.png')
}

.coupon-list .coupon-electronic .coupon-detail,.coupon-list .coupon-electronic .coupon-name,.coupon-list .coupon-electronic .use {
    color: #eca7be
}

.coupon-list .coupon-electronic.disable {
    background-image: url('https://sh-user.51alm.com/asserts/bg-coupon-electronic-disable.png')
}

.coupon-list .coupon-cut {
    background-image: url('https://sh-user.51alm.com/asserts/bg-coupon-cut.png')
}

.coupon-list .coupon-cut .coupon-detail,.coupon-list .coupon-cut .coupon-name,.coupon-list .coupon-cut .use {
    color: #70b0e5
}

.coupon-list .coupon-cut.disable {
    background-image: url('https://sh-user.51alm.com/asserts/bg-coupon-cut-disable.png')
}

.coupon-list .coupon-present {
    background-image: url('https://sh-user.51alm.com/asserts/bg-coupon-present.png')
}

.coupon-list .coupon-present .coupon-detail,.coupon-list .coupon-present .coupon-name,.coupon-list .coupon-present .use {
    color: #f9b775
}

.coupon-list .coupon-present.disable {
    background-image: url('https://sh-user.51alm.com/asserts/bg-coupon-present-disable.png')
}

.coupon-list .coupon-discount {
    background-image: url('https://sh-user.51alm.com/asserts/bg-coupon-discount.png')
}

.coupon-list .coupon-discount .coupon-detail,.coupon-list .coupon-discount .coupon-name,.coupon-list .coupon-discount .use {
    color: #1fbccf
}

.coupon-list .coupon-discount.disable {
    background-image: url('https://sh-user.51alm.com/asserts/bg-coupon-discount-disable.png')
}

.coupon-list .disable,.coupon-list .disable .coupon-detail,.coupon-list .disable .coupon-name,.coupon-list .disable .use {
    color: #b7b7b7
}

.coupon-list .item-box {
    background: #fff
}

.abandon-discount {
    position: relative;
    line-height: .4rem;
    background: #fff;
    text-align: center
}

.abandon-discount:before {
    content: " ";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: .01rem;
    border-top: .01rem solid #e4e4e4;
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    -webkit-transform: scaleY(.5);
    transform: scaleY(.5)
}

.arrive-wrap {
    display: -ms-flexbox;
    display: flex;

    -webkit-box-orient: vertical;
    -ms-flex-direction: column;
    flex-direction: column;
    height: 100%;
    overflow: hidden
}

.arrive-wrap .flex-item {
    -ms-flex: 1;
    flex: 1;
    -webkit-box-flex: 1;
    overflow: hidden
}

.arrive-error-wrap {
    background: #fff;
    height: 100%;
    padding-top: .2rem;
    text-align: center
}

.text-img {
    background: url('https://sh-user.51alm.com/asserts/preview.png') no-repeat;
    width: 100%;
    height: 100px
}

.arrive-list {
    -ms-flex: 1;
    flex: 1;
    -webkit-box-flex: 1;
    overflow: auto
}

.arrive-list .single-item-list {
    line-height: .3rem
}

.arrive-list .single-item-list .single-item {
    display: block
}

.arrive-list .content-box {
    padding-bottom: .1rem
}

.arrive-list .icon-box [class*=icon-] {
    margin: .11rem 0 0
}

.arrive-list .status {
    position: absolute;
    right: .07rem;
    top: .12rem;
    color: #999
}

.arrive-btn {
    position: relative;
    background: #fff;
    padding: .2rem .07rem
}

.arrive-btn:after {
    content: " ";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: .01rem;
    border-bottom: .01rem solid #e4e4e4;
    border-radius: .04rem;
    -webkit-transform-origin: 0 100%;
    transform-origin: 0 100%;
    -webkit-transform: scaleY(.5);
    transform: scaleY(.5)
}

.arrive-btn [class*=btn-] {
    width: .8rem;
    line-height: .3rem
}

.arrive-footer {
    position: static;
    height: .4rem
}

.arrive-footer .btn-box {
    position: relative;
    display: -ms-flexbox;
    display: flex;
    display: -webkit-box
}

.arrive-footer .btn-box:before {
    content: " ";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: .01rem;
    border-top: .01rem solid #e4e4e4;
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    -webkit-transform: scaleY(.5);
    transform: scaleY(.5)
}

.arrive-footer .btn-box [class*=btn-] {
    -ms-flex: 1;
    flex: 1;
    -webkit-box-flex: 1;
    line-height: .4rem;
    border-radius: 0
}

.arrive-footer .btn-box [class*=btn-]:after {
    display: none
}

.user-info-wrap .icon-allorder {
    vertical-align: -.03rem;
    margin-right: .04rem
}

.user-info-wrap .icon-join,.user-info-wrap .icon-mycoupon,.user-info-wrap .icon-logout {
    vertical-align: -.05rem;
    margin-right: .08rem
}

.user-info-wrap .header-box {
    background-image: url('https://sh-user.51alm.com/asserts/bg-user-center.png');
    background-repeat: no-repeat;
    background-size: 100% auto;
    background-position: 0 -.72rem
}

.user-info-wrap .header-box .user-detail-info {
    margin-bottom: .3rem;
    text-align: center;
    color: #fff
}

.user-info-wrap .header-box .user-detail-info .btn-blue-circle {
    width: .8rem;
    line-height: .3rem;
    margin: 0 auto
}

.user-info-wrap .header-box .user-detail-info .user-avatar {
    padding-top: .15rem;
    margin-bottom: .1rem
}

.user-info-wrap .header-box .user-detail-info .user-avatar .img-box {
    width: .7rem;
    height: .7rem;
    margin: 0 auto;
    border-radius: 50%
}

.user-info-wrap .header-box .user-detail-info .user-avatar .img-box img {
    display: block;
    width: 100%;
    height: 100%
}

.user-info-wrap .header-box .user-detail-info .user-name {
    margin-bottom: .06rem;
    font-size: .14rem
}

.user-info-wrap .header-box .user-detail-info .user-phone {
    font-size: .12rem
}

.user-info-wrap .header-box .account-info {
    height: 1.12rem;
    margin: 0 .07rem;
    border-radius: .05rem;
    background: #fff;
    box-shadow: 0 .03rem .09rem 0 rgba(31,188,207,.3)
}

.user-info-wrap .header-box .account-info .title {
    line-height: .2rem;
    padding: .1rem;
    color: #1fbccf
}

.user-info-wrap .header-box .account-info .title .icon-wallet {
    margin-right: .07rem
}

.user-info-wrap .header-box .account-info .content {
    text-align: center
}

.user-info-wrap .header-box .account-info .content .rest-info-box {
    color: #1fbccf
}

.user-info-wrap .header-box .account-info .content .rest-info-box .price {
    font-size: .3rem
}

.user-info-wrap .header-box .account-info .content .rest-info-box .small {
    font-size: .17rem
}

.user-info-wrap .header-box .account-info .content .recharge-info {
    padding: .15rem 0;
    font-size: .12rem;
    color: #f23030
}

.user-info-wrap .body-box .user-info-section {
    background: #fff;
    margin-top: .1rem
}

.user-info-wrap .body-box .user-info-section .list-item {
    position: relative;
    line-height: .44rem;
    padding: 0 .07rem;
    color: #999
}

.user-info-wrap .body-box .user-info-section .list-item:after {
    content: " ";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: .01rem;
    border-bottom: .01rem solid #e4e4e4;
    border-radius: .04rem;
    -webkit-transform-origin: 0 100%;
    transform-origin: 0 100%;
    -webkit-transform: scaleY(.5);
    transform: scaleY(.5)
}

.user-info-wrap .body-box .user-info-section .list-item .icon-coupon,.user-info-wrap .body-box .user-info-section .list-item .icon-order,.user-info-wrap .body-box .user-info-section .list-item .icon-union {
    margin-right: .1rem
}

.user-info-wrap .body-box .user-info-section .list-item .side-info {
    float: right;
    font-size: .12rem
}

.user-info-wrap .body-box .user-info-section .list-item .side-info .icon-arrow-right {
    margin-left: .05rem
}

.user-info-wrap .body-box .user-info-section .order-type {
    display: -ms-flexbox;
    display: flex;
    display: -webkit-box
}

.user-info-wrap .body-box .user-info-section .order-type .flex-item {
    -ms-flex: 1;
    flex: 1;
    -webkit-box-flex: 1;
    overflow: hidden
}

.user-info-wrap .body-box .user-info-section .order-type .item {
    -ms-flex: 1;
    flex: 1;
    -webkit-box-flex: 1;
    overflow: hidden;
    position: relative;
    height: .7rem
}

.user-info-wrap .body-box .user-info-section .order-type .item .item-box {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%,-50%);
    transform: translate(-50%,-50%)
}

.user-info-wrap .body-box .user-info-section .order-type .item .top {
    position: relative;
    text-align: center;
    margin-bottom: .04rem
}

.user-info-wrap .body-box .user-info-section .order-type .item .top .message-dot {
    position: absolute;
    right: -.12rem;
    top: 0;
    width: .12rem;
    height: .12rem;
    background: #f23030;
    border-radius: .06rem;
    color: #fff;
    font-size: .09rem
}

.user-info-wrap .body-box .user-info-section .order-type .item .bottom {
    text-align: center;
    font-size: .12rem;
    color: #999
}

.user-detail-wrap .user-detail-section {
    background: #fff
}

.user-detail-wrap .user-detail-section .list-item {
    position: relative;
    padding: 0 .07rem;
    min-height: .48rem;
    box-sizing: border-box
}

.user-detail-wrap .user-detail-section .list-item:after {
    content: " ";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: .01rem;
    border-bottom: .01rem solid #e4e4e4;
    border-radius: .04rem;
    -webkit-transform-origin: 0 100%;
    transform-origin: 0 100%;
    -webkit-transform: scaleY(.5);
    transform: scaleY(.5)
}

.user-detail-wrap .user-detail-section .list-item .title {
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%)
}

.user-detail-wrap .user-detail-section .list-item .content {
    padding: .08rem 0;
    text-align: right
}

.user-detail-wrap .user-detail-section .list-item .content .avatar-box {
    display: inline-block;
    width: .6rem;
    height: .6rem
}

.user-detail-wrap .user-detail-section .list-item .content .avatar-box img {
    width: 100%;
    height: 100%
}

.user-detail-wrap .user-detail-section .list-item .content .text {
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    right: .07rem
}

.user-detail-wrap .footer-box {
    padding: .2rem 0;
    text-align: center
}

.user-detail-wrap .footer-box .btn-signout {
    display: inline-block;
    width: 2.5rem;
    height: .4rem;
    line-height: .4rem;
    border-radius: .03rem;
    background: #b3b3b3;
    color: #fff
}

.address-detail-wrap img {
    width: 100%
}

.declaration-wrap {
    color: #999
}

.declaration-wrap h1 {
    padding: .2rem 0 .15rem;
    font-size: .2rem;
    text-align: center
}

.declaration-wrap p {
    padding: .05rem .07rem;
    line-height: .18rem
}
