.tab-bar {
    display: flex;
    justify-content: space-around;
    position: fixed;
    bottom: 0;
    width: 100%;
    background-color: #fff;
    border-top: 1px solid #ccc;
  }
  
  .tab-bar a {
    text-decoration: none;
    color: #000;
    padding: 10px;
  }
  
  .tab-bar a.active {
    color: #007bff;
  }
  