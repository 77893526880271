@charset "UTF-8";
header,
section,
footer {
  font-size: .22rem;
}
body{
  background-color: #fff;
  position: relative;
}

section.half{
  display: flex;
  height: 6.2rem;
  background-color: #1fbccf;
}

section.login{
  position: absolute;
  top: 140px;
  margin: 20px;
  border-radius: .12rem;
  background-color: #fff;
  z-index: 1000;
  -webkit-box-shadow: 0 0 .16rem rgba(31,188,207,0.3);
  -moz-box-shadow: 0 0 .16rem rgba(31,188,207,0.3);
  -ms-box-shadow: 0 0 .16rem rgba(31,188,207,0.3);
  -o-box-shadow: 0 0 .16rem rgba(31,188,207,0.3);
  box-shadow: 0 0 .6rem rgba(31,188,207,0.3);
  .logo{
    padding: .2rem;
    position: absolute;
    top: -.7rem;
    left: 50%;
    border-radius: 50%;
    margin-left: -.6rem;
    background-color: #fff;
    z-index: 1100;
    img{
      width: 80px;
      height: 80px;
    }
  }
  h3{
    margin-top: 80px;
    font-size: 20px;
    font-weight: 500;
    color: #1FBCCF;
    text-align: center;
  }
  .form{
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    .user_phone{
      padding: 20px;
      background-color: #fff;
      margin-bottom: .04rem;
      display: flex;
      justify-content: center;
      align-items: center;
      .phone{
        flex: 9;
        font-size: .24rem;
        line-height: .6rem;
        border-bottom: .02rem solid #e4e4e4;
        label{
          width: 0.4rem;
          height: 0.4rem;
          float: left;
          display: inline-block;
          img{
            margin-top: .14rem;
            width: .34rem;
            height: .34rem;
          }
        }
        input{
          width: 80%;
          float: left;
          height: 0.5rem;
          outline: none;
          margin-left: .1rem;
          border: none;
          padding: .1rem 0;
          font-size: .24rem;

        }
        input::-webkit-input-placeholder { // 输入框提示颜色
          color: #ccc;
        }

      }
      span{
        flex: 1;
        text-align: center;
        color: #999;
      }
    }
    .user_code{
      padding: 0 20px 0 20px;
      background-color: #fff;
      display: flex;
      justify-content: center;
      align-items: center;

      .code{
        display: flex;
        font-size: .20rem;
        line-height: .6rem;
        border-bottom: .02rem solid #e4e4e4;
        label{
          width: 0.4rem;
          height: 0.4rem;
          display: inline-block;
          img{
            margin-top: .14rem;
            width: .34rem;
            height: .34rem;
          }
        }
        input{
          height: 0.5rem;
          outline: none;
          margin-left: .1rem;
          border: none;
          padding: .1rem 0;
          font-size: .2rem;
          width: 100%;
        }
        input::-webkit-input-placeholder {
          color: #ccc;
        }
        .code_btn{
          height: .6rem;
          padding: 0 .1rem;
          border: none;
          color: #fff;
          border-radius: .04rem;
          background-color: #60CADB;
          outline: none;
          width: 100px;
        }
      }

    }
    .sub_btn{
      margin: 16% 5% 10px;
      height: 50px;
      line-height: 10px;
      width: 80%;
      border: none;
      color: #fff;
      font-size: .3rem;
      border-radius: .4rem;
      background-color: #60CADB;
      outline: none;
    }
  }
}



