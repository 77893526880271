// styles.scss
$primary-color: #3498db;

.my-component {
  background-color: lighten($primary-color, 20%);
  padding: 20px;
  border: 1px solid darken($primary-color, 10%);

  p {
    font-size: 16px;
    color: $primary-color;
  }
}
