.store-item {
    display: flex;
    padding: 10px;
    border-bottom: 1px solid #ccc;
}

.store-image {
    width: 100px;
    height: 100px;
    margin-right: 10px;
}

.store-info {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.store-projects {
    display: flex;
    flex-direction: column;
}

.store-project {
    display: flex;
    justify-content: space-between;
}
