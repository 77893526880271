.my-component {
  background-color: #f0f0f0;
  padding: 20px;
  border: 1px solid #ccc;

  p {
    font-size: 16px;
    color: #333;
  }
}

.error-page {
  background-color: red;
  padding: 20px;
  border: 1px solid #ccc;
}
